import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-company-warning',
  standalone: true,
  imports: [],
  templateUrl: './company-warning.component.html',
  styleUrl: './company-warning.component.scss'
})
export class CompanyWarningComponent {

  @ViewChild('okBtn') focusedElement: ElementRef;

  message = '';
  title = '';
  breakWordFlag: boolean = false;
  timer: any = [];
  constructor( public dialogRef: MatDialogRef<CompanyWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data)
    
  }

  ngOnInit() {
    this.initDialog();
    console.log(this.data);
    console.log(this.message);
    this.timer.push(setTimeout(() => { this.focusedElement.nativeElement.focus(); }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }
 
  confirm() {
     this.dialogRef.close(true);
    this.clearTime();
  }

  Close() {
     this.dialogRef.close(false);
    this.clearTime()
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
}
