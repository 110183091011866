import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  AuthService,
  OrganizationService,
  ClientsService,
  ConfigService,
  UsersService,
  SearchMasterService,
  UsermessageService,
  GroupsService,
  OrdersService,
  AwsS3Service,
  TagsService,
} from "../../services";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from "../../dialog/success/success.component";
import { CONSTANTS } from "../../app.constants";
import { ConfirmComponent } from "../../dialog/confirm/confirm.component";
import { AddClientOrgsComponent } from "../../dialog/add-client-orgs/add-client-orgs.component";
import { AddAdmin2companyComponent } from "../../dialog/add-admin2company/add-admin2company.component";
import { ErrorDialogComponent } from "../../dialog/error-dialog/error-dialog.component";
import { environment } from "environments/environment";
import { UUID } from "angular2-uuid";
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogMessageComponent } from "app/dialog/error-dialog-message/error-dialog-message.component";
import { forkJoin } from "rxjs";
import { CompanyWarningComponent } from "app/dialog/company-warning/company-warning.component";

@Component({
  selector: "app-edit-organization",
  templateUrl: "./edit-organization.component.html",
  styleUrls: ["./edit-organization.component.scss"],
})
export class EditOrganizationComponent implements OnInit {
  @ViewChild("myFirstFocus") myFirstFocus: ElementRef;
  editOrgFrm: UntypedFormGroup;
  newDeliveryGroupFrm: UntypedFormGroup;
  editDeliveryGroupFrm: UntypedFormGroup;
  editOrgModel: any = {};
  editOrgTrack: any = {};
  error: string = "";
  softproFrgnId:string='';
  errorMsg: any = {};
  delGrpErrMsg: any = {};
  admins = [];
  clients = [];
  customers = [];
  constants = CONSTANTS;
  isClientsReady = false;
  availableAdmins = [];
  availableSearchMasters = [];
  searchMasters = [];
  domainName: string;
  isCreateGroupClicked: boolean = false;
  newDeliveryGroup: any = {};
  availableGroups = [];
  groupMembers = [];
  marketingGroupMembers = [];
  allAvailableProducts = [];
  selectedSalesPerson = null;
  selectedDefaultProduct = null;
  selectedClientType: any;
  listingType: string;
  isIndexMatches: any;
  editDeliveryGroup: any = {};
  isGroupEditable: boolean = false;
  isMailsToAllCmpCli: boolean;
  isDemo: boolean;
  isNewClient: boolean;
  NewClient: boolean;
  isAutoScreen: boolean;
  invToDeliverables: boolean;
  notifyAllClientsInCompany: boolean;
  isDocInReport: boolean;
  searchPackageTemplates = [];
  selectedTemplate: any;
  remoteLanguage: boolean;
  commitmentTemplates = [];
  selectedCommitmentTemplate = null;
  allActiveTags = [];
  selectedDefaultTags = [];
  clientOrderNotes = {};
  clientBillingNotes = {};
  previousClient:any;
  timer: any = [];
  clientType = [
    { value: "0", viewValue: "None" },
    { value: "1", viewValue: "Qualia Client" },
    { value: "2", viewValue: "TrackerPro Client" },
    { value: "4", viewValue: "SoftPro Client" },
    { value: "8", viewValue: "Resware Client" },
  ];
  addLogo = [2, 3];
  authToken: string = null;
  subscriptionData: any = {
    Watch_Subscription_Status: 0,
  };
  orgId: any;

  documents = [];
  cpnyDocs = [];
  public imageBlob: any = null;
  public fileSizeError = false;
  tinyOptions: any = {
    auto_focus: false,
    branding:false,
    promotion :false,
    skin:'oxide',
    menubar:false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };
  @ViewChild("fileInput") fileInput: ElementRef;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private matDialog: MatDialog,
    private auth: AuthService,
    private config: ConfigService,
    private usersService: UsersService,
    private masterService: SearchMasterService,
    private cd: ChangeDetectorRef,
    private userMsg: UsermessageService,
    public groupsService: GroupsService,
    private s3Service: AwsS3Service,
    private orderService: OrdersService,
    private tagService: TagsService
  ) {
    this.editOrgFrm = fb.group({
      Organization_Name: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(127)]),
      ],
      Organization_Description: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(225)]),
      ],
      Organization_Domain: [null],
      Softpro_Frgn_Id: [
        null,
        Validators.compose([Validators.maxLength(127)]),
      ],
      userActive: [null],
      Client_Order_Info: [null],
      Billing_Info:[null]
    });
    this.editOrgFrm.valueChanges.subscribe((data) => {
      this.error = "";
    });
    this.newDeliveryGroupFrm = fb.group({
      Group_Name: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(127)]),
      ],
      Email_Address: [null, Validators.compose([Validators.required])],
      Client_Member: [null],
      Email_Members_Flag: [false],
    });
    this.newDeliveryGroupFrm.valueChanges.subscribe((data) => {
      this.error = "";
    });
    this.editDeliveryGroupFrm = fb.group({
      Group_Name: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(127)]),
      ],
      Email_Address: [null, Validators.compose([Validators.required])],
      Client_Member: [null],
      Client_Type: [null],
      Email_Members_Flag: [false],
    });
    this.editDeliveryGroupFrm.valueChanges.subscribe((data) => {
      this.error = "";
    });
    this.s3Service.progress
    .subscribe((res) => {
      var matchedIndex = this.documents.findIndex((doc) => doc.name == res.name);
      if (this.documents[matchedIndex]) {
        this.documents[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
        this.documents[matchedIndex].completed = Math.round(this.documents[matchedIndex].completed);
      }
    });
  }

  ngOnInit() {
    this.orgId = this.route.snapshot.params["id"];
    this.getSalesMarketingGrpMembers();
    this.getAllActiveTags();
    this.getAllOrganizationProducts(this.route.snapshot.params["id"]);
    this.getOrganization();
    this.getAllAdmins();
    this.getAllClients();
    this.getOrgDeliveryGroups();
    this.getAllClientsToAddOrgs();
    this.fetchToken();
    this.getWatchStatus();
    this.getSearchPackageTemplates();
    this.getCommitmentTemplates();
    this.timer.push(
      setTimeout(() => {
        this.config.setSideBarNode(0);
        this.myFirstFocus.nativeElement.focus();
      }, 0)
    );
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  getAllClientsToAddOrgs() {
    this.clientsService
      .getAllClientsToAddOrgs(this.route.snapshot.params["id"])
      .subscribe(
        (result) => {
          if (result) {
            this.customers = result;
            // console.log("ngOnInit result", result);
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
  }

  getOrganization() {
    this.organizationService
      .getOrganizationDetails(this.route.snapshot.params["id"])
      .subscribe(
        (res) => {
          this.softproFrgnId=res.Softpro_Frgn_Id;
          this.editOrgModel = res;
          this.loadData(res);
          this.setToggleStatusForMails(res);
          this.setToggleStatusForDemoflag(res);
          this.setToggleStatusForNewCliFlag(res);
          this.setToggleStatusForAutoScreenFlag(res);
          this.setToggleStatusForUpdInvDelFlag(res);
          this.setToggleForEmailNotifyForMsg(res);
          this.setToggleForDocsInReport(res);
          this.setSalesPersonField(res);
          this.setDefaultTagsField(res);
          this.setDefaultProductField(res);
          this.setCommitmentTemplateField(res);
          this.setToggleStatusForRemoteLang(res);
          this.getLogo(res.Logo);
          this.getClientOrderInfo();
          this.getClientBillingInfo();
          this.getDocMappings();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getDocMappings(){
    this.organizationService.getAllCompanyMappings(this.route.snapshot.params["id"])
    .subscribe((docs) => {
      this.cpnyDocs = docs;
    })
  }

  showLogo() {
    return this.addLogo.includes(this.selectedTemplate);
  }

  getLogo(logoPath) {
    if (logoPath && logoPath !== undefined) {
      this.s3Service.downloadFromPath(logoPath, "Logo").then((downloadLink) => {
        this.imageBlob = downloadLink;
      });
    } else this.imageBlob = null;
  }

  loadData(data) {
    this.selectedTemplate = data.SP_Template;
    if (this.editOrgModel.Organization_Name) {
      document
        .getElementById("Organization_Name")
        .parentElement.classList.remove("is-empty");
    }
    if (this.editOrgModel.Organization_Description) {
      document
        .getElementById("Organization_Description")
        .parentElement.classList.remove("is-empty");
    }
    if (this.editOrgModel.Organization_Domain) {
      document
        .getElementById("Organization_Domain")
        .parentElement.classList.remove("is-empty");
    }
    this.getClientTypeFlag(data);
  }

  getClientTypeFlag(data) {
    if (data.Organization_Type == 1)
      this.listingType = this.clientType[1].value;
    else if (data.Organization_Type == 2)
      this.listingType = this.clientType[2].value;
    else if (data.Organization_Type == 4)
      this.listingType = this.clientType[3].value;
    else if (data.Organization_Type == 8)
      this.listingType = this.clientType[4].value;
    else this.listingType = this.clientType[0].value;


    this.previousClient = this.listingType;
  }

  userAction(event, client) {
    console.log("Event", client);
    if (
      client.User_Status == this.constants.userStatus.inActive ||
      client.User_Status == this.constants.userStatus.deActivated
    ) {
      this.openConfirm(
        "Activate",
        "Would you like to re-activate this client ?"
      )
        .afterClosed()
        .subscribe((res) => {
          if (res == true) {
            console.log("Ok");
            this.activateUsr(client);
          } else {
            console.log("Cancelled");
            client.userState = !client.userState;
          }
        });
    } else {
      this.openConfirm(
        "Deactivate",
        "Would you like to de-activate this client ?"
      )
        .afterClosed()
        .subscribe((res) => {
          if (res == true) {
            console.log("Ok");
            this.deactivateUsr(client);
          } else {
            console.log("Cancelled");
            client.userState = !client.userState;
          }
        });
    }
  }

  openConfirm(title, msg) {
    return this.matDialog.open(ConfirmComponent, {
      data: {
        title: title,
        message: msg,
      },
      ...this.config.getDialogOptions(),
    });
  }
  openAddClient(title, msg) {
    return this.matDialog.open(AddClientOrgsComponent, {
      data: {
        title: title,
        message: {
          Organization_ID: msg.Organization_ID,
          Assigned_Clients: this.clients,
          Clients: this.customers,
        },
      },
      ...this.config.getDialogOptions(),
    });
  }

  activateUsr(client) {
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: client.User_ID,
    };
    this.clientsService.activateUser(data).subscribe(
      (data) => {
        console.log(data);
        // this.openModal('Success', 'Client re-activated successfully.');
        // this.router.navigate(['/clients'])
      },
      (err) => {
        console.log(err);
        this.errorMsg = err;
      }
    );
  }

  deactivateUsr(client) {
    var data = {
      Admin_ID: this.auth.getUserId(),
      User_ID: client.User_ID,
    };
    this.clientsService.deactivateUser(data).subscribe(
      (data) => {
        console.log(data);
        // this.openModal('Success', 'Client deactivated successfully.');
        // this.router.navigate(['/clients'])
      },
      (err) => {
        console.log(err);
        this.errorMsg = err;
      }
    );
  }

  editOrganization() {
    this.editOrgModel.Organization_Sales_Person_ID = this.selectedSalesPerson;
    this.editOrgModel.Organization_Default_Product =
      this.selectedDefaultProduct;
    this.editOrgModel.Admin_User_ID = this.auth.getUserId();
    this.editOrgModel.SP_Template = this.selectedTemplate;
    this.editOrgModel.Def_Comm_Temp_ID = this.selectedCommitmentTemplate;
    this.editOrgModel.Organization_Default_Tags = this.selectedDefaultTags;
    this.organizationService
      .editOrganizationDetails(
        this.editOrgModel,
        this.route.snapshot.params["id"]
      )
      .subscribe(
        (data) => {
          // console.log(data);
          let msg = "Updated successfully.";
          this.userMsg.setSuccessMsg(msg);
          this.router.navigate(["/organization/list"]);
          // this.openModal('Success', 'Updated successfully.');
        },
        (err) => {
          console.log(err);
          this.errorMsg = err;
        }
      );
  }

  getAllAdmins() {
    this.organizationService
      .getAllAdmins(this.route.snapshot.params["id"])
      .subscribe(
        (data) => {
          this.admins = data.filter(
            (user) =>
              user.User_Role == CONSTANTS.userRole.admin ||
              user.User_Role == CONSTANTS.userRole.superAdmin ||
              user.User_Role == CONSTANTS.userRole.compAdmin
          );
          this.searchMasters = data.filter(
            (user) => user.User_Role == CONSTANTS.userRole.searchMaster
          );
          // console.log("Popup all admin data", this.admins);
        },
        (err) => {
          console.log(err);
          this.errorMsg = err;
        }
      );
  }

  getAllClients() {
    // this.organizationService
    //   .getAllClients(this.route.snapshot.params["id"])
    //   .subscribe(
    //     (data) => {
    //       // console.log("client 1: ", this.admins);
    //       this.clients = data;

    //       this.setUserState(this.clients);
    //       // console.log("client 2: ", this.clients);
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.errorMsg = err;
    //     }
    //   );

    this.usersService
      .getAllCompanyClientsById(this.route.snapshot.params["id"])
      .subscribe(
        (data) => {
          // console.log("client 1: ", this.admins);
          this.clients = data;

          this.setUserState(this.clients);
          // console.log("client 2: ", this.clients);
        },
        (err) => {
          console.log(err);
          this.errorMsg = err;
        }
      );
  }

  setUserState(clients) {
    clients.forEach((client) => {
      if (
        client.User_Status == CONSTANTS.userStatus.inActive ||
        client.User_Status == this.constants.userStatus.deActivated
      ) {
        client.userState = false;
      } else {
        client.userState = true;
      }
      this.cd.detectChanges();
    });
  }

  openModal(title, msg) {
    let disposable = this.matDialog
      .open(SuccessComponent, {
        data: {
          title: title,
          message: msg,
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {
        this.router.navigate(["/organization/list"]);
      });
  }

  createClient() {
    this.openAddClient("Client", this.editOrgModel)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res == "new")
            this.router.navigate([
              "/client/create/" + this.editOrgModel.Organization_ID,
            ]);
          else {
            if (res != "cancel" || res == "new") {
              var custIndex = this.customers.findIndex(
                (customer) => customer.User_ID == res
              );
              // this.usersService
              //   .getDomainName(this.customers[custIndex].Email_Address)
              //   .then((domain: any) => {
              //     this.domainName = domain;
              //     this.switchToCompany({
              //       Organization_ID: this.editOrgModel.Organization_ID,
              //       User_Modified_By: this.auth.getUserId(),
              //       User_ID: this.customers[custIndex].User_ID,
              //       Organization_Domain: this.domainName,
              //     });
              //   });

              let obj = {
                User_ID: this.customers[custIndex].User_ID,
                Organization_ID: this.route.snapshot.params["id"],
                User_Modified_By: this.auth.getUserId(),
                Softpro_Frgn_Id: this.softproFrgnId
              };
              this.usersService.addUserToCompany(obj).subscribe(
                (result) => {
                  this.getAllClients();
                  this.getAllClientsToAddOrgs();
                },
                (err) => {
                  console.log(err);
                }
              );
            }
          }
        }
      });
  }

  switchToCompany(data) {
    this.usersService.changeToCompany(data).subscribe(
      (res) => {
        // this.editOrgModel.Organization_Domain = data.Organization_Domain
        this.getAllClients();
        this.getAllClientsToAddOrgs();
      },
      (err) => {
        this.errorMsg = err;
      }
    );
  }

  removeClient(client) {
    console.log("client", client);
    client.User_Modified_By = this.auth.getUserId();
    // this.usersService.changeToIndividual(client).subscribe(
    //   (result) => {
    //     this.getAllClients();
    //     this.getAllClientsToAddOrgs();
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    this.usersService.RemoveUserFromCompany(client).subscribe(
      (result) => {
        this.getAllClients();
        this.getAllClientsToAddOrgs();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteAdmin(admin) {
    this.organizationService.deleteOrganization(admin).subscribe(
      (result) => {
        if (result) {
          this.getAllAdmins();
        }
      },
      (err) => {
        this.errorMsg = err;
      }
    );
  }

  addAdmin() {
    this.usersService.getAllAdminsToOrderAssign().subscribe((adminList) => {
      if (adminList) this.availableAdmins = adminList;
      console.log("Available admins", this.availableAdmins);
      let disposable = this.matDialog
        .open(AddAdmin2companyComponent, {
          data: {
            message: {
              Organization_ID: this.editOrgModel.Organization_ID,
              Admins: this.availableAdmins,
              Assigned_Admins: this.admins,
              Type: "Admin",
            },
          },
          ...this.config.getDialogOptions(),
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) this.getAllAdmins();
        });
    });
  }

  getUserRole() {
    return Number(this.auth.getUserRole());
  }

  addSearchMaster() {
    this.masterService.getAllActiveMasters().subscribe((searcherList) => {
      if (searcherList) this.availableSearchMasters = searcherList;
      let disposable = this.matDialog
        .open(AddAdmin2companyComponent, {
          data: {
            message: {
              Organization_ID: this.editOrgModel.Organization_ID,
              Admins: this.availableSearchMasters,
              Assigned_Admins: this.searchMasters,
              Type: "Search Master",
            },
          },
          ...this.config.getDialogOptions(),
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) this.getAllAdmins();
        });
    });
  }

  addGroup() {
    this.isCreateGroupClicked = true;
    this.isGroupEditable = false;
    this.selectAllClients();
    if (this.delGrpErrMsg) this.delGrpErrMsg = {};
  }

  selectAllClients() {
    let selectAllCli = [];
    this.clients.filter((x) => {
      selectAllCli.push(x.User_ID);
    });
    this.newDeliveryGroup.Client_Member = selectAllCli;
  }

  newGroupMultiSelect(ev) {
    if (ev._selected) {
      let selectAllClients = [];
      this.clients.filter((x) => {
        selectAllClients.push(x.User_ID);
      });
      this.newDeliveryGroupFrm.controls["Client_Member"].setValue(
        selectAllClients
      );
      ev._selected = true;
    }
    if (ev._selected == false) {
      this.newDeliveryGroupFrm.controls["Client_Member"].setValue([]);
    }
  }

  cancelDeliveryGroup() {
    this.isCreateGroupClicked = false;
    this.resetNewDeliveryGroupFrm();
    // this.newDeliveryGroup = {};
  }

  createDeliveryGroup() {
    if (this.delGrpErrMsg) this.delGrpErrMsg = {};
    this.newDeliveryGroup.Organization_ID = this.route.snapshot.params["id"];
    this.clientsService.createOrgGroup(this.newDeliveryGroup).subscribe(
      (result) => {
        if (result) {
          this.isCreateGroupClicked = false;
          this.getOrgDeliveryGroups();
          this.newDeliveryGroup = {};
        }
      },
      (err) => {
        this.delGrpErrMsg = err;
      }
    );
  }

  getOrgDeliveryGroups() {
    this.clientsService
      .getOrgDeliveryGroups(this.route.snapshot.params["id"])
      .subscribe(
        (result) => {
          if (result) {
            this.availableGroups = result;
          }
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.getDeliveryGroups);
        }
      );
  }

  editGroupMultiSelect(ev) {
    if (ev._selected) {
      let selectAllClients = [];
      this.clients.filter((x) => {
        selectAllClients.push(x.User_ID);
      });
      this.editDeliveryGroupFrm.controls["Client_Member"].setValue(
        selectAllClients
      );
      ev._selected = true;
    }
    if (ev._selected == false) {
      this.editDeliveryGroupFrm.controls["Client_Member"].setValue([]);
    }
  }

  getGroupMembers(group, i) {
    if (this.delGrpErrMsg) this.delGrpErrMsg = {};
    this.isIndexMatches = i;
    this.isGroupEditable = true;
    if (this.isCreateGroupClicked) {
      this.isCreateGroupClicked = false;
      this.resetNewDeliveryGroupFrm();
    }
    this.clientsService.getGroupMembers(group.Org_Delivery_Group_ID).subscribe(
      (result) => {
        if (result) {
          this.groupMembers = result;
          let usersAssociated = [];
          this.groupMembers.filter((x) => {
            usersAssociated.push(x.Company_Client_User_ID);
          });
          this.editDeliveryGroupFrm.controls["Client_Member"].setValue(
            usersAssociated
          );
          this.editDeliveryGroup.Group_Name =
            group.Org_Delivery_Group_Full_Name;
          this.editDeliveryGroup.Email_Address =
            group.Org_Delivery_Group_Email_Address;
          if (group.Org_Email_Members_Flag == this.constants.emailMembers.yes)
            this.editDeliveryGroup.Email_Members_Flag = true;
          else this.editDeliveryGroup.Email_Members_Flag = false;
        }
      },
      (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.getDeliveryGroupsUsers);
      }
    );
  }

  deleteDeliveryGroup(groupId) {
    this.clientsService.deleteDeliveryGroup(groupId).subscribe(
      (result) => {
        this.getOrgDeliveryGroups();
      },
      (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.delDeliveryGroup);
      }
    );
  }

  editDeleveryGroup(groupId) {
    if (this.delGrpErrMsg) this.delGrpErrMsg = {};
    this.editDeliveryGroup.Organization_ID = this.route.snapshot.params["id"];
    this.editDeliveryGroup.Group_ID = groupId;
    if (this.editDeliveryGroup.Email_Address != null) {
      if (!this.editDeliveryGroup.Email_Address.trim().length)
        this.editDeliveryGroup.Email_Address = null;
    }
    this.clientsService.editDeleveryGroup(this.editDeliveryGroup).subscribe(
      (result) => {
        if (result) {
          this.isGroupEditable = false;
          this.editDeliveryGroup = {};
          this.getOrgDeliveryGroups();
        }
      },
      (err) => {
        this.delGrpErrMsg = err;
      }
    );
  }

  cancelEditDeleveryGroup() {
    this.isGroupEditable = false;
    this.resetEditDeliveryGroupFrm();
    // this.editDeliveryGroup={};
  }

  setToggleStatusForMails(data) {
    if (
      data.Organization_Send_Direct_Email_To_Client ==
      this.constants.mailsToAllCmpCli.active
    ) {
      this.isMailsToAllCmpCli = true;
    } else {
      this.isMailsToAllCmpCli = false;
    }
  }

  userActionForMails(event) {
    this.isGroupEditable = false;
    this.isCreateGroupClicked = false;
    this.resetNewDeliveryGroupFrm();
    this.resetEditDeliveryGroupFrm();
    let EmailStatus;
    if (this.isMailsToAllCmpCli)
      EmailStatus = this.constants.mailsToAllCmpCli.active;
    else EmailStatus = this.constants.mailsToAllCmpCli.inActive;
    this.clientsService
      .setEmailStatusForCompCli(EmailStatus, this.route.snapshot.params["id"])
      .subscribe(
        (details) => {},
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateOrgMailStatus);
          this.isMailsToAllCmpCli = !this.isMailsToAllCmpCli;
        }
      );
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {
      data: {
        message: msg,
      },
      ...this.config.getDialogOptions(),
    });
  }

  resetNewDeliveryGroupFrm() {
    this.newDeliveryGroupFrm.controls["Group_Name"].reset();
    this.newDeliveryGroupFrm.controls["Email_Address"].reset();
    this.newDeliveryGroupFrm.controls["Client_Member"].reset();
    this.newDeliveryGroupFrm.controls["Email_Members_Flag"].reset();
  }

  resetEditDeliveryGroupFrm() {
    this.editDeliveryGroupFrm.controls["Group_Name"].reset();
    this.editDeliveryGroupFrm.controls["Email_Address"].reset();
    this.editDeliveryGroupFrm.controls["Client_Member"].reset();
    this.newDeliveryGroupFrm.controls["Email_Members_Flag"].reset();
  }

  setToggleStatusForDemoflag(data) {
    if (data.Organization_Demo_Flag == this.constants.isDemo.active) {
      this.isDemo = true;
    } else {
      this.isDemo = false;
    }
  }

  setToggleStatusForDemoOrg() {
    let toggleStatus;
    if (this.isDemo) toggleStatus = this.constants.isDemo.active;
    else toggleStatus = this.constants.isDemo.inActive;
    this.organizationService
      .updateDemoStatusForOrg(toggleStatus, this.route.snapshot.params["id"])
      .subscribe(
        (details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.isDemo = !this.isDemo;
        }
      );
  }

  setToggleStatusForNewCliFlag(data) {
    if (data.Organization_New_Client_Flag == this.constants.clientType.new) {
      this.isNewClient = true;
    } else {
      this.isNewClient = false;
    }

    if (data.New_Organization == this.constants.clientType.new)
      this.NewClient = true;
    else this.NewClient = false;
  }

  updateNewClientStatus() {
    let toggleStatus;
    if (this.isNewClient) toggleStatus = this.constants.clientType.new;
    else toggleStatus = this.constants.clientType.old;
    this.organizationService
      .updateNewClientStatusForOrg(
        toggleStatus,
        this.route.snapshot.params["id"]
      )
      .subscribe(
        (details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.isNewClient = !this.isNewClient;
        }
      );
  }

  updateNewClient() {
    let toggleStatus;
    if (this.NewClient) toggleStatus = this.constants.clientType.new;
    else toggleStatus = this.constants.clientType.old;
    this.organizationService
      .updateNewClientForOrg(toggleStatus, this.route.snapshot.params["id"])
      .subscribe(
        (details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.NewClient = !this.NewClient;
        }
      );
  }

  setToggleStatusForAutoScreenFlag(data) {
    if (
      data.Organization_Auto_Screen_Flag == this.constants.autoScreen.active
    ) {
      this.isAutoScreen = true;
    } else {
      this.isAutoScreen = false;
    }
  }

  updateAutoScreenStatus() {
    let toggleStatus;
    if (this.isAutoScreen) toggleStatus = this.constants.autoScreen.active;
    else toggleStatus = this.constants.autoScreen.inActive;
    this.organizationService
      .updateAutoScreenStatusForOrg(
        toggleStatus,
        this.route.snapshot.params["id"]
      )
      .subscribe(
        (details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.isAutoScreen = !this.isAutoScreen;
        }
      );
  }

  setToggleStatusForUpdInvDelFlag(data) {
    if (
      data.Org_AddInvoice_Deliverables == this.constants.invToDeliverables.yes
    ) {
      this.invToDeliverables = true;
    } else {
      this.invToDeliverables = false;
    }
  }

  updInvDelStatus() {
    let toggleStatus;
    if (this.invToDeliverables)
      toggleStatus = this.constants.invToDeliverables.yes;
    else toggleStatus = this.constants.invToDeliverables.no;
    this.organizationService
      .updInvDelStatus(toggleStatus, this.route.snapshot.params["id"])
      .subscribe(
        (details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.invToDeliverables = !this.invToDeliverables;
        }
      );
  }

  setToggleForEmailNotifyForMsg(data) {
    if (
      data.Organization_Message_Notify_Flag == this.constants.msgNotify.active
    ) {
      this.notifyAllClientsInCompany = true;
    } else {
      this.notifyAllClientsInCompany = false;
    }
  }

  setToggleForDocsInReport(data) {
    if (data.SP_DocInReport == 1) {
      this.isDocInReport = true;
    } else {
      this.isDocInReport = false;
    }
  }

  setToggleStatusForRemoteLang(data) {
    if (data.remoteLanguage == 1) {
      this.remoteLanguage = true;
    } else {
      this.remoteLanguage = false;
    }
  }

  userActionForEmailNotify() {
    let toggleStatus;
    if (this.notifyAllClientsInCompany)
      toggleStatus = this.constants.msgNotify.active;
    else toggleStatus = this.constants.msgNotify.inActive;
    this.organizationService
      .updateMsgNotifyStatusForOrg(
        toggleStatus,
        this.route.snapshot.params["id"]
      )
      .subscribe(
        (details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.notifyAllClientsInCompany = !this.notifyAllClientsInCompany;
        }
      );
  }

  changePrepaymentStatus(event) {
    if (event.checked)
      this.updatePrepaymentStatus(
        this.editOrgModel.Organization_PrePayment_Status
      );
    else
      this.updatePrepaymentStatus(
        this.editOrgModel.Organization_PrePayment_Status
      );
  }

  updatePrepaymentStatus(paymentStatus) {
    this.organizationService
      .changePaymentStatus(this.editOrgModel.Organization_ID, paymentStatus)
      .subscribe(() => {
        this.getOrganization();
      });
  }

  changeClientType() {
    console.log(this.selectedClientType);
    if(this.listingType == this.clientType[0].value && this.previousClient == this.clientType[3].value){
      this.matDialog.open(CompanyWarningComponent,{data: {
          title: "Client Type Conversion Warning",
          message: `You are about to change the client type from a SoftPro Company to a non-SoftPro Company. This action will remove all existing foreign ID mappings associated with this company.<br><br>Are you sure you want to proceed?`,
        },
        ...this.config.getDialogOptions()}).afterClosed().subscribe((res)=>{
          if(res){
            this.updateClientType(this.listingType);
            this.previousClient = this.listingType;
          }else{
              this.listingType=this.previousClient;
          }
        })
    }else{
      this.updateClientType(this.listingType);
      this.previousClient = this.listingType;
    }
   
  }

  updateClientType(clientType) {
    this.organizationService
      .changeClientType(this.editOrgModel.Organization_ID, clientType)
      .subscribe(() => {
        this.getOrganization();
      });
  }

  useRemoteLanguage(event) {
    console.log("=========useRemoteLanguage==========>", event.checked);
    this.organizationService
      .changeRemoteLang(this.editOrgModel.Organization_ID, event.checked)
      .subscribe(() => {
        this.getOrganization();
      });
  }

  getSalesMarketingGrpMembers() {
    this.groupsService
      .getGroupMembers(environment.salesMarketingGrpId)
      .subscribe(
        (users) => {
          this.marketingGroupMembers = users;
        },
        (err) => {
          this.openErrorPopup("Error while fetching data.");
        }
      );
  }

  getAllActiveTags() {
    this.tagService.getAllActiveTagsForListing().subscribe(
      (activeTags) => {
        if (activeTags) {
          this.allActiveTags = activeTags.rows;
        }
      },
      (err) => {
        this.openErrorPopup("Error while fetching tags.");
      }
    );
  }

  getAllOrganizationProducts(orgId) {
    this.orderService.getOrderProductsForClient(orgId).subscribe(
      (products) => {
        this.allAvailableProducts = products;
      },
      (err) => {
        this.openErrorPopup("Error while fetching organization products data.");
      }
    );
  }

  setSalesPersonField(data) {
    if (data.Organization_Sales_Person_ID) {
      this.selectedSalesPerson = data.Organization_Sales_Person_ID;
    }
  }

  setDefaultTagsField(data) {
    if (
      data.Organization_Default_Tags &&
      data.Organization_Default_Tags.trim()
    ) {
      this.selectedDefaultTags = data.Organization_Default_Tags.split(",")
        .map((item) => parseInt(item.trim(), 10)) // Parse each item to an integer
        .filter((item) => !isNaN(item)); // Filter out NaN values;
    }
  }

  setDefaultProductField(data) {
    if (data.Organization_Default_Product) {
      this.selectedDefaultProduct = data.Organization_Default_Product;
    }
  }

  setCommitmentTemplateField(data) {
    if (data.Def_Comm_Temp_ID) {
      this.selectedCommitmentTemplate = data.Def_Comm_Temp_ID;
    }
  }

  generateAuthToken() {
    this.organizationService
      .generateAuthToken(this.editOrgModel.Organization_ID)
      .subscribe(
        () => {
          this.fetchToken();
        },
        () => {
          this.openErrorPopup("Failed to generate Auth token.");
        }
      );
  }

  fetchToken() {
    this.organizationService
      .fetchAuthToken(this.route.snapshot.params["id"])
      .subscribe((data) => {
        this.authToken = data.token;
      });
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Token copied successfully");
  }

  updateWatchStatus() {
    let postData = {
      Company_ID: this.route.snapshot.params["id"],
      Watch_Subscribed_User_ID: this.auth.getUserId(),
      Watch_Subscription_Status:
        this.subscriptionData.Watch_Subscription_Status,
    };
    if (this.subscriptionData.Company_Watch_Subscription_ID)
      postData["Company_Watch_Subscription_ID"] =
        this.subscriptionData.Company_Watch_Subscription_ID;
    this.organizationService.syncCompanyWatch(postData).subscribe(
      (details) => {
        this.userMsg.setSuccessMsg("Updated successfully.");
        this.getWatchStatus();
      },
      (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.companyWatchStatusToggle);
        this.subscriptionData.Watch_Subscription_Status =
          !this.subscriptionData.Watch_Subscription_Status;
      }
    );
  }

  getWatchStatus() {
    this.organizationService
      .getWatchStatus({
        Admin_User_ID: this.auth.getUserId(),
        Company_ID: this.route.snapshot.params["id"],
      })
      .subscribe(
        (result) => {
          if (result) this.subscriptionData = result;
          else
            this.subscriptionData = {
              Watch_Subscription_Status: 0,
            };
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.companyWatchStatus);
        }
      );
  }

  editLogo = async (e: any) => {
    const fileToUpload = e.target.files.item(0);
    this.fileSizeError = fileToUpload.size > 2048000;
    if (this.fileSizeError) {
      setTimeout(() => {
        this.fileSizeError = false;
      }, 5000);
    } else {
      this.uploadToS3AndUpdatePath(e.target.files[0]);
    }
  };

  uploadToS3AndUpdatePath(file) {
    return this.s3Service
      .onManagedUpload(file, "0")
      .then((data) => {
        this.organizationService
          .updateLogo(this.editOrgModel.Organization_ID, data.Key)
          .subscribe(() => {
            if (this.editOrgModel.Logo)
              this.s3Service.deleteFromS3(this.editOrgModel.Logo);
            this.getOrganization();
          });
      })
      .catch((err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.docupload);
      });
  }

  getSearchPackageTemplates() {
    this.organizationService.getSearchPackageTemplates().subscribe(
      (data) => (this.searchPackageTemplates = data),
      (err) => {
        console.log(err);
        this.errorMsg = err;
      }
    );
  }

  getCommitmentTemplates() {
    this.organizationService.getCommitmentTemplates().subscribe(
      (data) => {
        this.commitmentTemplates = data.sort((a, b) => {
          return a.Name.localeCompare(b.Name);
        });
      },
      (err) => {
        this.openErrorPopup("Error while fetching commitment templates.");
      }
    );
  }

  getClientOrderInfo() {
    this.orderService
      .getClientInfoFromGsheet(this.editOrgModel.Organization_ID)
      .subscribe(
        (data) => {
          this.clientOrderNotes = data[0] ? data[0] : {};
        },
        (err) => {
          this.openErrorPopup("Error while fetching clientOrderInfo.");
        }
      );
  }

  getClientBillingInfo() {
    this.orderService
      .getBillingInfoFromGsheet(this.editOrgModel.Organization_ID)
      .subscribe(
        (data) => {
          this.clientBillingNotes = data[0] ? data[0] : {};
        },
        (err) => {
          this.openErrorPopup("Error while fetching clientBillingInfo.");
        }
      );
  }

  removeLogo() {
    this.matDialog
      .open(ConfirmComponent, {
        data: {
          title: "Remove customer logo",
          message: "Are you sure you want to remove customer logo ?",
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.organizationService
            .updateLogo(this.editOrgModel.Organization_ID, null)
            .subscribe(() => {
              if (this.editOrgModel.Logo)
                this.s3Service.deleteFromS3(this.editOrgModel.Logo);
              this.getOrganization();
            });
        }
      });
  }

  updateDocInReportStatus() {
    let toggleStatus = this.isDocInReport ? 1 : 0;
    this.organizationService
      .updateDocInReportStatusForOrg(
        toggleStatus,
        this.route.snapshot.params["id"]
      )
      .subscribe(
        (details) => {
          this.userMsg.setSuccessMsg("Updated successfully.");
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.updateInsertRecordStatus);
          this.isDocInReport = !this.isDocInReport;
        }
      );
  }

  saveClientInfo(){
    this.organizationService.updateClientOrderInfoForOrg(this.clientOrderNotes, this.editOrgModel.Organization_ID)
    .subscribe((data) => {
      this.clientOrderNotes = data[0] ? data[0] : {};
      this.userMsg.setSuccessMsg("Updated successfully.");
    },(err) => {
      this.openErrorPopup("Error while updating clientOrderInfo.");
    })
    
  }

  saveBillingInfo(){
    this.organizationService.updateClienBillingInfoForOrg(this.clientBillingNotes, this.editOrgModel.Organization_ID)
    .subscribe((data) => {
      this.clientBillingNotes = data[0] ? data[0] : {};
      this.userMsg.setSuccessMsg("Updated successfully.");
    },(err) => {
      this.openErrorPopup("Error while updating clientBillingInfo.");
    })

  }

  isSuperAdmin(){
    return (Number(this.auth.getUserRole())  == this.constants.userRole.superAdmin) ||( Number(this.auth.getUserRole())  == this.constants.userRole.compAdmin)
  }

  uploadDocs() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  async uploadDocument(event) {
    let newDocs = [];
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      var encryptedFiles = [];
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) {
          let isEncrypted = await this.handleEncryptedFiles(
            event.target.files[i]
          );
          if (isEncrypted && isEncrypted == true)
            encryptedFiles.push(event.target.files[i].name);
          else {
            var uid = UUID.UUID();
            this.documents.push({
              docId: uid,
              name: event.target.files[i].name,
              completed: 0,
            });
            fileUploads.push(
              this.updCpnyDocToS3AndUpdatePath(
                event.target.files[i],
                uid
              )
            );
          }
        }
        if (encryptedFiles.length > 0)
          this.openErrorMsgPopup(
            CONSTANTS.apiErrors.encryptedFileError,
            encryptedFiles
          );
        forkJoin(fileUploads).subscribe((files) => {
          this.removeCompletedDocs(files);
          var succesFiles = files.filter((file) => file !== undefined);
          if (succesFiles.length > 0) {
            newDocs = newDocs.concat(succesFiles);
          }
        });
        Promise.all(fileUploads)
          .then((files) => {
            this.organizationService.createCompanyDocs({ docs: files })
            .subscribe((savedDocs) => {
              this.cpnyDocs = this.cpnyDocs.concat(savedDocs);
            })
          })
          .catch((err) => {
            this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
          });
      } else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    } else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile);
    }
  }

  updCpnyDocToS3AndUpdatePath(file, filename) {
    return this.s3Service
      .onManagedCpnyFileUpload(file, this.route.snapshot.params["id"], filename)
      .then((data) => {
        if (data) {
          let doc = {
            Company_Id: this.route.snapshot.params["id"],
            Doc_S3_Path : data.Key,
            Doc_Name: this.extractFilename(data.Key),
            Doc_Original_Name : file.name,
            Created_By : this.auth.getUserId(),
            docId: filename,
          };
          return doc;
        }
      })
      .catch((err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
      });
  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.documents.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.documents[j].docId) {
          this.documents.splice(j, 1);
          len2 = this.documents.length;
        }
      }
    }
  }

  openErrorMsgPopup(msg, list) {
    let disposable = this.matDialog
      .open(
        ErrorDialogMessageComponent,
        {data:{
          message: msg,
          itemList: list,
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {});
  }

  validateFilenames(event) {
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(",") > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(
      files,
      (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024
    );
    if (largeFiles.length > 0) return false;
    else return true;
  }

  handleEncryptedFiles(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      let encrypted = false;
      reader.onload = function (event: any) {
        let fileContents = event.target.result;
        if (fileContents.indexOf("/Encrypt") !== -1) {
          encrypted = true;
        }
        resolve(encrypted);
      };
      reader.readAsText(file);
      reader.onerror = function (event: any) {
        console.error("Error while reading file contents.");
      };
    });
  }

  extractFilename(filePath) {
    var parts = filePath.split("/");
    var filename = parts[parts.length - 1];
    return filename;
  }

  downloadFile(documentData) {
    this.s3Service
      .downloadFromPath(documentData.Doc_S3_Path, documentData.Doc_Original_Name)
      .then((downloadLink) => {
        let link: any = document.createElement("a");
        document.body.appendChild(link);
        link.download = documentData.Doc_Original_Name;
        link["href"] = downloadLink;
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
      });
  }

  deleteFile(document, index) {
    this.matDialog
      .open(
        ConfirmComponent,
        {data:{
          title: "Delete Document",
          message:
            "Are you sure you want to delete " + document.Doc_Original_Name + " ?",
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.organizationService.deleteCompanyMapping(document.Id)
          .subscribe(() => {
            this.cpnyDocs.splice(index, 1);
          }, (err) => {
            this.openErrorPopup("Unable to delete " + document.Doc_Original_Name);
          });
        } else {
          console.log("Closed", res);
        }
      });
  }
}
