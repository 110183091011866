
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  AuthService, PagerService, ConfigService, OrdersService, PricingService, StateService, OrganizationService, ScrollService, UsersService,
  AbstractorService, TagsService, UsermessageService, SchedulingService
} from '../../services';
import { Router } from '@angular/router';
import { PreloaderService } from '../../services/preloader/preloader.service';
import { CONSTANTS } from '../../app.constants';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { MatMenuTrigger } from '@angular/material/menu';
import { SearchPipe } from '../../pipes/search/search.pipe';
import { DatePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AssignComponent } from '../../dialog/assign/assign.component';
import { ScreenOrderComponent } from '../../dialog/screen-order/screen-order.component';
import { EtaChangeReasonComponent } from '../../dialog/eta-change-reason/eta-change-reason.component';
import * as _ from 'underscore';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.scss'],
  providers: [SearchPipe],
  encapsulation:  ViewEncapsulation.None
})
export class AllOrdersComponent implements OnInit {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  orders: any = [];
  totalCount: number;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  constants = CONSTANTS;
  pager: any = {};
  sortField: string;
  orderBy: string;
  pageNum: number;
  scrollEnable: boolean = true;
  ordersToDisplay: any;
  organizations = [];
  allorganizations = [];
  filterBy: any = [];
  selectedOrgId = '';
  selectedOrderAdminId = '';
  selectedOrderAbsId = '';
  status: string;
  dueDate: string;
  errors: any = {};
  orderStatus: any = {};
  listingType = 'filterByAll';
  adminList: any = [];
  searchMastersList: any = [];
  abstractorsList: any = [];
  handleBy: string;
  searchString: string;
  searchInProgress: boolean = false;
  chipTotal: number;
  selectedTags = [];
  allActiveTags = [];
  allNegativeTags = [];
  tagsOrderCount = [];
  retainChipTotal: number;
  selectedorg = [];
  ordersDueList = [
    { value: 'Select all' },
    { value: 'Past due' },
    { value: 'Due today' },
    { value: 'Due tomorrow' },
    { value: 'Due in the future' },
    { value: 'Due today (Original ETA)' },
  ];
  availableChips = [
    { label: 'Completed', selected: false, negated: true, showForPast: false, value: [CONSTANTS.orderStatus.completed] },
    { label: 'Processing', selected: false, negated: false, showForPast: true, value: [CONSTANTS.orderStatus.processing] },
    { label: 'Not Processing', selected: false, negated: false, showForPast: true, value: [CONSTANTS.orderStatus.received, CONSTANTS.orderStatus.assigned, CONSTANTS.orderStatus.confirmed] },
  ];
  allTagsChip = [
    { label: 'All Tags', selected: false },   
  ];
  orderTags = [];
  searchTag: string = '';
  associatedTags = [];
  ordsToassign = [];
  selectedOrgIds = [];
  searchQuery = new Subject<string>();
  timer: any = [];
  selectAbs = '';
  selectAdmin = '';
  newClientTagId = '';
  priorityClientTagId = '';
  firstOrdTagId = '';
  holidayList: any;
  prePaidTagId= '';
  absDoubleAssignTagId = '';
  selectAllTags: boolean = false;
  negativeTag: boolean = false;
  intialTag: boolean = true;
  selectedNegTags =[];
  isNegTagSelected: boolean = false;
  allActiveTagsBackUp = [];
  currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
  constructor(
    private auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    public orderService: OrdersService,
    private preloaderService: PreloaderService,
    private pricingService: PricingService,
    private stateService: StateService,
    private organizationService: OrganizationService,
    private scrollService: ScrollService,
    private userService: UsersService,
    private matDialog:MatDialog,
    private abstractorService: AbstractorService,
    private tagService: TagsService,
    private searchPipe: SearchPipe,
    private _datePipe: DatePipe,
    private userMsg: UsermessageService,
    private holidayScheduling: SchedulingService
  ) {
        this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.orderBy = 'ASC';
    this.sortField = 'Order_ETA';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    this.auth.refreshToken();
    // this.getAvailableTagLocal();
    this.dueDate = 'Due today';
    this.filterBy.push({ Due_Date: this.dueDate });
    if (this.stateService.getAllOrders()) {
      this.initializeComponentData(this.stateService.getAllOrders());
    } else {
    this.getAllActiveTags();
    this.captureSelectionChange();
    }
    if(this.config.getNewClientTagId()) this.newClientTagId = this.config.getNewClientTagId();
    if(this.config.getPriorityClientTagId()) this.priorityClientTagId = this.config.getPriorityClientTagId();
    if(this.config.getFirstOrdTagId()) this.firstOrdTagId = this.config.getFirstOrdTagId();
    if(this.config.getPrePaidTagId()) this.prePaidTagId = this.config.getPrePaidTagId();
    if(this.config.getAbsDoubleAssignTagId()) this.absDoubleAssignTagId = this.config.getAbsDoubleAssignTagId();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(19), 0));
    this.getAdminOrganizationList();
    this.getAllOrganizationList();
    this.orderStatus = this.orderService.getOrderStatus();
    // this.getholidaylist();
    this.getholidaylistFromLocal();
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
//   getAvailableAbstractorsLocal(){
//     let AllAbstractors = JSON.parse(sessionStorage.getItem('AllAbstractors'));
//     if(AllAbstractors) this.getAbstractorsList(AllAbstractors)         
//     else this.getAvailableAbstractorList();
// }
  
//   getAvailableAbstractorList(){
//     this.abstractorService.getAllAbstractorsList().subscribe((abstractors) => { 
//       if(abstractors){
//         this.getAbstractorsList(abstractors)         
//         sessionStorage.setItem('AllAbstractors',JSON.stringify(abstractors)); 
//        setTimeout(function(){sessionStorage.removeItem("AllAbstractors");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     },
//     (err) => {
//       this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
//     })
//   }

  getAbstractorsList() {
    this.abstractorService.getAllAbstractorsList()
      .subscribe((abslist) => {
        this.abstractorsList = abslist;
      },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        });
  }

  // getAbstractorsList(abslist) {
  //       this.abstractorsList = abslist;
  // }
  getAdminList() {
    var data = {};
    if (this.selectedOrgId) data['Organization_ID'] = this.selectedOrgId;
    this.userService.getAllOrgAdminsToOrderAssign(data)
      .subscribe((adminData) => {
        this.adminList = adminData;
      },
        (err) => {
          console.log("err", err);
          // this.errorMsg = err;
        });
  }

  getSearchMasterList() {
    var data = {};
    if (this.selectedOrgId) data['Organization_ID'] = this.selectedOrgId;
    this.userService.getAllOrgSearchMasterToOrderAsign(data)
      .subscribe((searchMastersData) => {
        this.searchMastersList = searchMastersData;
      },
        (err) => {
          console.log("err", err);
          // this.errorMsg = err;
        });
  }
  getAdminOrganizationList() {
    this.organizationService.addedOrganizationList(this.auth.getUserId())
      .subscribe((result) => {
        if (result) {
          this.organizations = result;
        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }

  getAllOrganizationList() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.allorganizations = result;
        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }

  detectTypeChange(event) {
    if (event.value == 'admin') {
      this.selectedOrderAdminId = '';
      this.selectedOrderAbsId = '';
      this.searchMastersList = [];
      this.abstractorsList = [];
      this.handleBy = "admin";
      this.applyFilter2();
      this.getAdminList();
    }
    if (event.value == 'searcher') {
      this.selectedOrderAdminId = '';
      this.selectedOrderAbsId = '';
      this.adminList = [];
      this.abstractorsList = [];
      this.handleBy = "searcher";
      this.applyFilter2();
      this.getSearchMasterList();
    }
    if (event.value == 'abstractor') {
      this.selectedOrderAdminId = '';
      this.selectedOrderAbsId = '';
      this.adminList = [];
      this.searchMastersList = [];
      this.handleBy = "abstractor";
      this.applyFilter2();
      this.getAbstractorsList();
      // this.getAvailableAbstractorsLocal();
    }
    if (event.value == 'unassigned') {
      this.applyFilter(true);
      // this.getAvailableAbstractorsLocal();
    }
    if (event.value == 'filterByAll') {
      this.applyFilter(true);
    }
  }

//   getAvailableTagLocal(){
//     let AllTag = JSON.parse(sessionStorage.getItem('AllTagsAvailable'));
//     if(AllTag) this.getAllActiveTags(AllTag)         
//     else this.getAvailableTagList();
// }
  
//   getAvailableTagList(){
//     this.tagService.getAllActiveTagsForListing().subscribe((tags) => { 
//       if(tags){
//         this.getAllActiveTags(tags)         
//         sessionStorage.setItem('AllTagsAvailable',JSON.stringify(tags)); 
//        setTimeout(function(){sessionStorage.removeItem("AllTagsAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     }, (err) => {
//         this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
//     })
//   }

  getAllActiveTags() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        if (activeTags) {
          this.allActiveTags = []
          if (this.selectedTags.length > 0 || this.allNegativeTags.length>0) {
            activeTags.rows.forEach((chip) => {
              let index=this.allNegativeTags.indexOf(chip.Tag_ID);
              if(index == -1){
                index = this.selectedTags.indexOf(chip.Tag_ID);
                if (index > -1) chip.selected = true;
                this.allActiveTags.push(chip);
              }  
            })
          }else this.allActiveTags=activeTags.rows; 
          if (this.tagsOrderCount.length > 0) this.addOrderCountToTags(this.tagsOrderCount);
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  // getAllActiveTags(activeTags) {
  //       if (activeTags) {
  //         this.allActiveTags = activeTags.rows;
  //         if (this.selectedTags.length > 0) {
  //           this.allActiveTags.map((chip) => {
  //             var index = this.selectedTags.indexOf(chip.Tag_ID);
  //             if (index > -1) chip.selected = true;
  //           })
  //         }
  //         if (this.tagsOrderCount.length > 0) this.addOrderCountToTags(this.tagsOrderCount);
  //       }
  // }

  onChipSelect(chip,i) {
    if(this.selectAllTags && this.negativeTag && Boolean(chip.selected)){
      this.deselctAllTagsChip();
      this.onNegSelectionChange(chip, i);
    }else{
      this.deselctAllTagsChip();
        chip.selected = !chip.selected;
        this.selectAllTags = true;
        this.negativeTag = true;
      this.onSelectionChange(chip);
    }
  }

  onNegSelectionChange(chip,i){
    var index = this.selectedNegTags.indexOf(chip.Tag_ID);
    if (index > -1) {
      this.selectedNegTags.splice(index, 1);
      this.allActiveTags.push(chip)
      chip.selected = !chip.selected
      if(this.allNegativeTags.length>0) this.allNegativeTags.splice(i,1)
      this.isNegTagSelected = true;
      this.pageNum = 1;
      this.setScrollResetPos()
      this.getOrders();
    } else{ 
      this.selectedNegTags.push(chip.Tag_ID);
      this.allActiveTags.splice(i,1)
      this.allNegativeTags.push(chip)
      this.allNegativeTags.map((chip) => {
          chip.deselected = true
        })
      this.isNegTagSelected = false;
      this.onSelectionChange(chip);
    }
  }

  onSelectionChange(chip) {
    var index = this.selectedTags.indexOf(chip.Tag_ID);
    if (index > -1) {
      this.selectedTags.splice(index, 1);
    } else this.selectedTags.push(chip.Tag_ID);
    this.pageNum = 1;
    this.setScrollResetPos()
    this.getOrders();
  }

  onAllTagsChipChange(chip) {
    this.selectAllTags = true;
    if(chip.selected == false){ 
      if(this.allNegativeTags.length>0) this.deselctAllNegTagsChip()
      this.negativeTag = true;
    }
    if(chip.selected == true) {
      this.selectAllTags = false;
      this.negativeTag = false;
    }
    chip.selected = !chip.selected;
    this.preSelectAllTagChips()
  }

  preSelectAllTagChips() {
    this.selectedTags = [];
    if(this.selectAllTags) {
      this.allActiveTags.map((chip) => {
        if(chip.Order_Count > 0) {
          chip.selected = true;
          this.selectedTags.push(chip.Tag_ID);
        }
      })
    } else {
      this.allActiveTags.map((chip) => chip.selected = false);
    }
      this.pageNum = 1;
      this.setScrollResetPos()
      this.getOrders();
  }

  deselctAllTagsChip() {
    // this.allTagsChip.map((chip) => chip.selected = false);
  }

  loadPage(orders) {
    // console.log("orders==============>", orders)
    this.tagsOrderCount = orders.tagsWithOrderCount;
    this.searchInProgress = false;
    this.orders = orders.allOrders.rows;
    this.totalCount = orders.allOrders.count;
    this.ordsToassign = [];
    this.selectedOrgIds = [];
    this.currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
    this.addCheckedProperty(this.orders);
    this.addOrderCountToTags(orders.tagsWithOrderCount);
    this.getOrdersRecordsData(this.totalCount);
    this.getScrollPosData();
    // if(this.availableChips.filter((chip) => !!chip.selected).length == 0)this.initMatChips(orders);
    // this.initMatChips(orders);
    this.initMatChips(orders);
  }

  getOrders() {
        if (this.pageNum == 1) {
      this.stateService.setAllOrders(this);
      this.orderService.getAllOrdersForDueDate(this.pageNum, this.orderBy, this.sortField, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags)
        .subscribe((orders) => {
          if (orders) {
            this.loadPage(orders);
          }
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else {
      this.getRetainData();
    }
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  searchField(search) {
    this.scrollService.setScrollPosAllOrder(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (!format.test(this.searchString) && this.searchString==" "){
      this.searchString='';
    this.getOrders();
  }else this.findOrders();
  }

  findOrders() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getOrders();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  getUserRole() {
    return Number(this.auth.getUserRole())
  }

  deselctingValues(){
    this.deselctAllTagsChip();
    if (this.selectedTags.length > 0) this.deselectAllTagChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip()
    this.deselectAllChips();
    this.selectedOrderAdminId = '';
    this.selectedOrderAbsId = '';
    this.searchMastersList = [];
    this.adminList = [];
    this.abstractorsList = [];
  }

  applyFilter(filterFlag = false) {
    if(!filterFlag){
      if(this.selectedOrgId && this.selectedOrgId.length!=this.selectedorg.length) {
        this.deselctingValues();
        this.listingType = 'filterByAll';
      }else if(!this.selectedOrgId && !filterFlag) {
        this.deselctingValues();
        this.listingType = 'filterByAll';
    }else if(this.selectedOrgId && this.listingType == 'filterByAll' && this.selectedTags.length == 0){
      this.deselctingValues();
      this.listingType = 'filterByAll';
  }
    }else {
    this.deselctingValues();
    if(this.listingType!='unassigned'){
      this.listingType = 'filterByAll';
    }
    // this.listingType = 'filterByAll';
  }
    this.pageNum = 1;
    this.filterBy = [];
    if (this.selectedOrgId && this.selectedOrgId != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId });
    if (this.dueDate) this.filterBy.push({ Due_Date: this.dueDate });
    if(this.listingType=='unassigned'){
      this.filterBy.push({Order_Admin_ID:['none']})
    }
    this.setScrollResetPos();
    // this.setScrollResetPos();

    this.allActiveTags.forEach((activeChip) => activeChip.Order_Count = 0);
    this.allNegativeTags.forEach((negativeChip) => negativeChip.Order_Count = 0);
    this.orders = [];

    if(!filterFlag){
      if(this.selectedOrgId && this.selectedOrgId.length!=this.selectedorg.length) this.captureSelectionChange(); // this.getOrders();
      else if(!this.selectedOrgId && !filterFlag) this.captureSelectionChange(); //this.getOrders();
    }else this.captureSelectionChange(); //this.getOrders();

  }

  applyFilter2(filterFlag = false) {
    this.pageNum = 1;
    if (this.handleBy && this.handleBy == 'admin') {
      var handleByIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Handle_By'));
      var orderAdminFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Admin_ID'));
      var orderAbstractorFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Abstractor_ID'));
      if (orderAdminFilterIndex > -1) {
        this.filterBy.splice(orderAdminFilterIndex, 1);
      }
      if (orderAbstractorFilterIndex > -1) {
        this.filterBy.splice(orderAbstractorFilterIndex, 1);
      }
      if (handleByIndex > -1) {
        this.filterBy[handleByIndex].Handle_By = [CONSTANTS.userRole.admin, CONSTANTS.userRole.compAdmin, CONSTANTS.userRole.superAdmin];
      }
      else {
        this.filterBy.push({ Handle_By: [CONSTANTS.userRole.admin, CONSTANTS.userRole.compAdmin, CONSTANTS.userRole.superAdmin] });
      }
    }
    if (this.handleBy && this.handleBy == 'searcher') {
      var handleByIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Handle_By'));
      var orderAdminFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Admin_ID'));
      var orderAbstractorFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Abstractor_ID'));
      if (orderAdminFilterIndex > -1) {
        this.filterBy.splice(orderAdminFilterIndex, 1);
      }
      if (orderAbstractorFilterIndex > -1) {
        this.filterBy.splice(orderAbstractorFilterIndex, 1);
      }
      if (handleByIndex > -1) {
        this.filterBy[handleByIndex].Handle_By = [CONSTANTS.userRole.searchMaster];
      }
      else {
        this.filterBy.push({ Handle_By: [CONSTANTS.userRole.searchMaster] });
      }
    }
    if (this.handleBy && this.handleBy == 'abstractor') {
      var handleByIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Handle_By'));
      var orderAdminFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Admin_ID'));
      var orderAbstractorFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Abstractor_ID'));
      if (orderAdminFilterIndex > -1) {
        this.filterBy.splice(orderAdminFilterIndex, 1);
      }
      if (orderAbstractorFilterIndex > -1) {
        this.filterBy.splice(orderAbstractorFilterIndex, 1);
      }
      if (handleByIndex > -1) {
        this.filterBy[handleByIndex].Handle_By = [CONSTANTS.userRole.abstractor];
      }
      else {
        this.filterBy.push({ Handle_By: [CONSTANTS.userRole.abstractor] });
      }
    }
    if (this.selectedOrderAdminId && this.selectedOrderAdminId != 'all') {
      var orderAdminFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Admin_ID'));
      var handleByIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Handle_By'));
      var orderAbstractorFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Abstractor_ID'));
      if (handleByIndex > -1) {
        this.filterBy.splice(handleByIndex, 1);
      }
      if (orderAbstractorFilterIndex > -1) {
        this.filterBy.splice(orderAbstractorFilterIndex, 1);
      }
      if (orderAdminFilterIndex > -1) {
        this.filterBy[orderAdminFilterIndex].Order_Admin_ID = this.selectedOrderAdminId
      }
      else {
        this.filterBy.push({ Order_Admin_ID: this.selectedOrderAdminId });
      }
    }
    if (this.selectedOrderAbsId && this.selectedOrderAbsId != 'all') {
      var orderAdminFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Admin_ID'));
      var handleByIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Handle_By'));
      var orderAbstractorFilterIndex = this.filterBy.findIndex((filterItem) => filterItem.hasOwnProperty('Order_Abstractor_ID'));
      if (handleByIndex > -1) {
        this.filterBy.splice(handleByIndex, 1);
      }
      if (orderAdminFilterIndex > -1) {
        this.filterBy.splice(orderAdminFilterIndex, 1);
      }
      if (orderAbstractorFilterIndex > -1) {
        this.filterBy[orderAbstractorFilterIndex].Order_Abstractor_ID = this.selectedOrderAbsId
      }
      else {
        this.filterBy.push({ Order_Abstractor_ID: this.selectedOrderAbsId });
      }
    }
    if(!filterFlag){
      if((this.selectedOrderAbsId || this.selectedOrderAdminId) && (this.selectedOrderAbsId!=this.selectAbs )|| (this.selectedOrderAdminId!=this.selectAdmin)){
        this.getAbsAdminValues();
      }else if((((this.handleBy=='abstractor' && !this.selectedOrderAbsId) ||((this.handleBy=='admin' || this.handleBy=='searcher') && !this.selectedOrderAdminId))) && !filterFlag){
        this.getAbsAdminValues();
      }
    }else{
      this.getAbsAdminValues();
    }
  }

  getAbsAdminValues(){
    this.deselctAllTagsChip();
    this.deselectAllChips();
    if (this.selectedTags.length > 0) this.deselectAllTagChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
    // this.setScrollResetPos();
    // this.getOrders();
    this.captureSelectionChange();
  }

  setScrollResetPos() {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosAllOrder(x);
  }

  getRetainData() {
    var ObsCollection = [];
    let pageSize = this.pageNum * this.config.getNumDueOrdRecordsPerPage();
    ObsCollection.push(this.orderService.getAllOrdersForDueDate(1, this.orderBy, this.sortField, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags, pageSize))
    
    // for (var i = 1; i <= this.pageNum; i++) {
    //   ObsCollection.push(this.orderService.getAllOrdersForDueDate(i, this.orderBy, this.sortField, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags))
    // }
    observableForkJoin(ObsCollection)
      .subscribe((retainedNewOrd) => {
        this.getRetainedNewOrd(retainedNewOrd);
      })

  }
  getRetainedNewOrd(retainedNewOrd) {
    var totalOrders = [];
    for (var i = 0; i < retainedNewOrd.length; i++) {
      this.stateService.setAllOrders(this);
      totalOrders = totalOrders.concat(retainedNewOrd[i].allOrders['rows']);
    }
    this.tagsOrderCount = retainedNewOrd[0]['tagsWithOrderCount'];
    this.orders = totalOrders;
    this.ordsToassign = [];
    this.selectedOrgIds = [];
    this.addCheckedProperty(this.orders);
    this.totalCount = retainedNewOrd[0].allOrders['count'];
    this.addOrderCountToTags(retainedNewOrd[0]['tagsWithOrderCount']);
    this.initMatChips(retainedNewOrd[0]);
    this.getOrdersRecordsData(this.totalCount);
    this.getScrollPosData();
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosAllOrder()) {
      var x = this.scrollService.getScrollPosAllOrder()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  getOrdersRecordsData(result) {
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPageDueOrders(result, this.pageNum);
    // this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumDueOrdRecordsPerPage());
    if (this.totalCount != undefined) this.ordersToDisplay = this.orders.slice(start, this.totalCount);
    if (this.ordersToDisplay.length > this.config.getNumDueOrdRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumDueOrdRecordsPerPage());
  }


  goToOrderDetails(orderId) {
   if(this.checkAdminType()){
    var getPos = document.documentElement.scrollTop
    this.scrollService.setScrollPosAllOrder(getPos);
    this.router.navigate(['/orders/' + orderId + '/view'])
   }
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumDueOrdRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.orderService.getAllOrdersForDueDate(this.pageNum, this.orderBy, this.sortField, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags)
        .subscribe((result) => {
          if (result) {
            this.totalCount = result.allOrders.count;
            if (this.totalCount > this.orders.length) {
              this.addCheckedProperty(result.allOrders.rows);
              this.orders = this.orders.concat(result.allOrders.rows);
              this.pager = this.pagerService.getPageDueOrders(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getOrders();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getOrders();
  }

  composeStatusClasses(order) {
    return {
      'due_in_2days': this.checkNextDays(order, 2),
      'due_today': this.checkIfDue(order),
      'past_due': this.checkIfMissed(order)
    }
  }

  checkNextDays(order, days) {
    // var momentAfter2Days = momentTimeZone.tz('America/New_York').add(days, 'days').format();
    // var todayMoment = momentTimeZone.tz('America/New_York').format();   
    // var orderETAMoment = momentTimeZone.tz(order.Order_ETA,'America/New_York');
    // if (orderETAMoment.isSameOrBefore(momentAfter2Days, 'day') && orderETAMoment.isAfter(todayMoment, 'day') && order.Order_Status != this.constants.orderStatus.completed) return true;
    // else return false;
    // var momentAfter2Days = momentTimeZone.tz('America/New_York').startOf('day').add(2, 'days').add(17, 'hours').utc().format();  //Gives moment after 2 days at 5 pm 
    // var currentUSStartDay = momentTimeZone.tz('America/New_York').startOf('day').add(17, 'hours').utc().format();  //Gives today date at 5 pm 
    // var tomorrowUSStartDay = momentTimeZone.tz('America/New_York').startOf('day').add(1, 'days').add(17, 'hours').utc().format();  //Gives tomo date at 5 pm 
    // var momentAfter3Days = momentTimeZone.tz('America/New_York').startOf('day').add(3, 'days').add(17, 'hours').utc().format();  //Gives moment after 3 days at 5 pm 
    // var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    // if (this.isCurrentDay()) {
    //   if (orderETAMoment.isAfter(currentUSStartDay) && orderETAMoment.isSameOrBefore(momentAfter2Days) && order.Order_Status != this.constants.orderStatus.completed) return true;
    //   else return false;
    // } else {
    //   if (orderETAMoment.isAfter(tomorrowUSStartDay) && orderETAMoment.isSameOrBefore(momentAfter3Days) && order.Order_Status != this.constants.orderStatus.completed) return true;
    //   else return false;
    // }

    var momentAfter24hr = momentTimeZone.tz('America/New_York').add(24, 'hours').utc().format();
    var momentAfter2Days = momentTimeZone.tz('America/New_York').add(24, 'hours').add(2, 'days').utc().format();
    var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    if (orderETAMoment.isSameOrAfter(momentAfter24hr) && orderETAMoment.isSameOrBefore(momentAfter2Days) && order.Order_Status != this.constants.orderStatus.completed) return true;
    else return false;
  }

  checkIfMissed(order) {
    // var momentToday = momentTimeZone.tz('America/Chicago').format();    
    // var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York'); 
    // if (orderETAMoment.isBefore(momentToday, 'day') && order.Order_Status != this.constants.orderStatus.completed) return true;
    // else return false;

    // var yersterdayUSStartDay = momentTimeZone.tz('America/New_York').startOf('day').add(-1, 'days').add(17, 'hours').utc().format();  //Gives yesterday date with 5 pm 
    // var currentUSStartDay = momentTimeZone.tz('America/New_York').startOf('day').add(17, 'hours').utc().format();  //Gives current date with 5 pm 
    // var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    // if (this.isCurrentDay()) {
    //   if (orderETAMoment.isSameOrBefore(yersterdayUSStartDay) && order.Order_Status != this.constants.orderStatus.completed) return true;
    //   else return false;
    // } else {
    //   if (orderETAMoment.isSameOrBefore(currentUSStartDay) && order.Order_Status != this.constants.orderStatus.completed) return true;
    //   else return false;
    // }

    var momentToday = momentTimeZone.tz('America/New_York').format();
    var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    if (orderETAMoment.isBefore(momentToday) && order.Order_Status != this.constants.orderStatus.completed) return true;
    else return false;

  }


  checkIfDue(order) {
    // var momentToday = momentTimeZone.tz('America/New_York').format();    
    // var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');   
    // if (orderETAMoment.isSame(momentToday, 'day') && order.Order_Status != this.constants.orderStatus.completed) return true;
    // else return false;

    // var yersterdayUSStartDay = momentTimeZone.tz('America/New_York').startOf('day').add(-1, 'days').add(17, 'hours').utc().format();  //Gives yesterday date with 5 pm 
    // var currentUSStartDay = momentTimeZone.tz('America/New_York').startOf('day').add(17, 'hours').utc().format(); //Gives current date with 5 pm 
    // var tomorrowUSStartDay = momentTimeZone.tz('America/New_York').startOf('day').add(1, 'days').add(17, 'hours').utc().format();  //Gives tomo date with 5 pm  
    // var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    // if (this.isCurrentDay()) {
    //   if (orderETAMoment.isAfter(yersterdayUSStartDay) && orderETAMoment.isSameOrBefore(currentUSStartDay) && order.Order_Status != this.constants.orderStatus.completed) return true;
    //   else return false;
    // } else {
    //   if (orderETAMoment.isAfter(currentUSStartDay) && orderETAMoment.isSameOrBefore(tomorrowUSStartDay) && order.Order_Status != this.constants.orderStatus.completed) return true;
    //   else return false;
    // }    

    var momentToday = momentTimeZone.tz('America/New_York').format();
    var momentAfter24hr = momentTimeZone.tz('America/New_York').add(24, 'hours').utc().format();
    var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
    if (orderETAMoment.isSameOrAfter(momentToday) && orderETAMoment.isSameOrBefore(momentAfter24hr) && order.Order_Status != this.constants.orderStatus.completed) return true;
    else return false;
  }

  isCurrentDay() {
    var currentDateAndTime = momentTimeZone.tz('America/New_York').utc().format();  //Gives current date and time of US	
    var currentUSStartDay = momentTimeZone.tz('America/New_York').startOf('day').add(17, 'hours').utc().format();  //Gives current date with 5 pm	
    if (moment(currentDateAndTime).isSameOrBefore(currentUSStartDay)) return true;
    else return false;
  }

  
  toggleChipSelect(chip) {
    this.deselctAllTagsChip();
    if (this.selectedTags.length > 0) this.deselectAllTagChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
    if(chip.selected){
      chip.selected = false; 
      chip.negated = true;
    } else if(chip.negated){
      chip.negated = false;
      chip.selected = false;
    } else chip.selected = true; 
    this.captureSelectionChange();
  }

  captureSelectionChange() {
    if(!this.filterBy.some((item) => item.hasOwnProperty('Order_Status'))) this.filterBy.push({ Order_Status: [] });
    if(!this.filterBy.some((item) => item.hasOwnProperty('Negate_Status'))) this.filterBy.push({ Negate_Status: [] });
    var selIdx = this.filterBy.findIndex((item) => item.hasOwnProperty('Order_Status'));
    var negIdx = this.filterBy.findIndex((item) => item.hasOwnProperty('Negate_Status'));
    this.filterBy[selIdx].Order_Status = [];
    this.filterBy[negIdx].Negate_Status = [];
    this.availableChips.forEach((chip)=>{
      if(chip.selected) this.filterBy[selIdx].Order_Status.push.apply(this.filterBy[selIdx].Order_Status, chip.value)
      if(chip.negated) this.filterBy[negIdx].Negate_Status.push.apply(this.filterBy[negIdx].Negate_Status, chip.value)
    })
    if(!this.filterBy[selIdx].Order_Status.length) this.filterBy.splice(selIdx, 1);
    negIdx = this.filterBy.findIndex((item) => item.hasOwnProperty('Negate_Status'));
    if(!this.filterBy[negIdx].Negate_Status.length) this.filterBy.splice(negIdx, 1);
    this.pageNum = 1;
    this.setScrollResetPos()
    this.getOrders();
  }

  initMatChips(data) {
    this.availableChips.forEach((chip) => {
      switch (chip.label) {
        case 'Completed': chip['count'] = data.completedOrdCount;
          break;
        case 'Processing': chip['count'] = data.inProgressOrdCount;
          break;
        case 'Not Processing': chip['count'] = data.notProcessedOrderCount;
          break;
        default:
          break;
      }
    })
    // this.chipTotal = data.allOrders.count;
    this.calculateTotalCount(data);
  }

  calculateTotalCount(data) {
    var total = 0;
    if (data.completedOrdCount) total += data.completedOrdCount;
    if (data.inProgressOrdCount) total += data.inProgressOrdCount;
    if (data.notProcessedOrderCount) total += data.notProcessedOrderCount;
    this.chipTotal = total;
    this.retainChipTotal = this.chipTotal;
  }

  deselectAllChips() {
    var orderStatusIndex = this.filterBy.findIndex((item) => item.hasOwnProperty('Order_Status'));
    if (orderStatusIndex > -1) {
      this.filterBy.splice(orderStatusIndex, 1);
    }
    this.availableChips.map((chip) => chip.selected = false);
  }

  getOrgList() {
    if (Number(this.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.getUserRole()) == CONSTANTS.userRole.compAdmin) return this.allorganizations;
    else return this.organizations;
  }

  getListOptions() {
    if (this.listingType == 'searcher') return this.searchMastersList;
    else if (this.listingType == 'admin') return this.adminList;
    else return [];
  }

  clearTags(){
    this.allTagsChip.map((chip) => chip.selected = false);
    this.selectedTags = [];
    this.allActiveTags.map((chip) => chip.selected = false);
    this.selectedNegTags =[];
    this.allNegativeTags.forEach((negTag)=>{
      negTag.deselected = false;
      negTag.selected = false;
      this.allActiveTags.push(negTag)
    })
    this.allNegativeTags = [];
    this.availableChips.forEach((chip)=>{
      chip.selected = false;
      chip.negated = false;
    })
  }

  resetFilter() {
    // if (this.selectedTags.length > 0) this.deselectAllTagChips();
    // if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
    this.searchString = '';
    this.selectedOrgId = '';
    this.dueDate = 'Due today';
    this.selectAllTags = false;
    this.listingType = "filterByAll";
    this.negativeTag = false;
    this.isNegTagSelected = false;
    if (this.filterBy) this.filterBy = [];
    if (this.dueDate) this.filterBy.push({ Due_Date: this.dueDate });
    // this.deselectAllChips();
    // this.deselctAllTagsChip();
    this.clearTags();
    this.getOrders();
  }

  checkIfFilterPresent() {
    if (this.searchString || this.selectedOrgId == 'all' || (this.filterBy.length > 1) || this.dueDate != 'Due today' || (this.selectedTags.length > 0) || (this.selectedNegTags.length > 0)) return true
    else return false
  }

  deselectAllTagChips() {
    // this.selectedTags = [];
    // this.allActiveTags.map((chip) => chip.selected = false);
  }

  deselctAllNegTagsChip(){
    // this.selectedNegTags =[];
    // this.allNegativeTags.map((chip) => chip.deselected = false)
    // this.allNegativeTags.forEach((negTag)=>{
    //   this.allActiveTags.push(negTag)
    // })
    // this.deselectAllTagChips()
    // this.allNegativeTags = [];
  } 

  initializeComponentData(data) {
    this.orderService.getDueOrderAssociatedTags(data.filterBy)
    .subscribe((tags)=>{
    let filterdata = _.intersection(data.selectedTags,tags)
    this.selectedTags = filterdata;
    this.selectedorg = data.selectedOrgId;
    this.selectAbs = data.selectedOrderAbsId;
    this.selectAdmin = data.selectedOrderAdminId;
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.selectedOrgId = data.selectedOrgId;
    this.dueDate = data.dueDate;
    this.filterBy = data.filterBy;
    this.listingType = data.listingType;
    this.totalCount = data.totalCount;
    this.handleBy = data.handleBy;
    this.selectedOrderAdminId = data.selectedOrderAdminId
    this.selectedOrderAbsId = data.selectedOrderAbsId
    this.ordersToDisplay = data.ordersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.availableChips = data.availableChips;
    this.searchMastersList = data.searchMastersList
    this.adminList = data.adminList;
    this.abstractorsList = data.abstractorsList;
    this.chipTotal = data.retainChipTotal;
    this.allTagsChip = data.allTagsChip;
    this.selectAllTags = data.selectAllTags;
    this.allNegativeTags = data.allNegativeTags;
    this.negativeTag = data.negativeTag;
    this.selectedNegTags = data.selectedNegTags;
    this.isNegTagSelected = data.isNegTagSelected;
    this.intialTag = data.intialTag;
    this.allActiveTags = data.allActiveTags 
    // this.getAllActiveTags();
    this.captureSelectionChange();
    })
  }

  openTagMenu(event, order) {
    event.stopPropagation();
    this.assignPreselectedTags(order);
  }

  openNewTagModal(order) {
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: order.Order_ID }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        this.tagsOrderCount = [];
        this.getAllActiveTags();
        // this.getAvailableTagLocal();
        this.getTagCount();
        if (selectedTagsCopy.length > 0) this.getOrders();
                else {
          this.getOrderTags(order);
          this.getTagCount();
        }
      }
    });
  }

  assignPreselectedTags(order) {
    if (order.Tag_Names) this.associatedTags = order.Tag_Names
  }

  getAvailableTags(order) {
    let preselectedTags = [];
    let availableTags = [];
    if (order.Tag_Names) preselectedTags = order.Tag_Names;
    for (var i = 0, len = this.allActiveTags.length; i < len; i++) {
      if (preselectedTags.findIndex((select => this.allActiveTags[i].Tag_ID == select.Tag_ID)) == -1) {
        if(this.allActiveTags[i].Tag_ID != this.newClientTagId && this.allActiveTags[i].Tag_ID != this.priorityClientTagId && this.allActiveTags[i].Tag_ID != this.firstOrdTagId && this.allActiveTags[i].Tag_ID != this.prePaidTagId) availableTags.push(this.allActiveTags[i]);
      }
    }
    return availableTags;
  }

  menuClosed(order) {
    this.searchTag = '';

  }

  updateOrderTags(order, associatedTags) {
    if (this.selectedTags.length > 0) this.deselectAllTagChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
    this.setScrollResetPos();
    this.orderService.updateOrderTags({ Order_ID: order.Order_ID, Order_Tags: this.orderTags.concat(associatedTags) }).subscribe(() => {
      this.matMenuTrigger.closeMenu();
      this.getOrders();
    })
  }

  checkIfAlreadyCompleted(orderStatusDet){
    let completedOrdIndex = orderStatusDet.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.completed)
    if(completedOrdIndex > -1) return true;
    else return false;
  }

  emitOrdTagCreateEvt() {
    this.getAllActiveTags();
    this.getOrders();
  }


  emitOrdTagChangeEvt() {
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    if (this.selectedTags.length > 0) this.deselectAllTagChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
    // if (selectedTagsCopy.length > 0) {
      this.getOrders();
    // }
    // else this.getTagCount();
  }

  modifyOrderTags(event, tag, order) {
    if(tag && tag.Tag_ID ==  this.config.getPartiallyDeliveredTagId()){      
      this.orderService.getAllOrderStatus(order.Order_ID)
      .subscribe((orderStatusDet) => {
        if(this.checkIfAlreadyCompleted(orderStatusDet)) this.modifyOrderTagsRequest(event, tag, order);
        else {
          this.openErrorPopup('This tag can only be assigned after an order has been completed at least once.');
          event.source.checked = false;
          this.matMenuTrigger.closeMenu();
        }
      })      
    }
    else this.modifyOrderTagsRequest(event, tag, order);    
  }

  modifyOrderTagsRequest(event, tag, order){
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    if (this.selectedTags.length > 0) this.deselectAllTagChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
    var isTagToAdd: boolean;
    if (event.checked) isTagToAdd = true;
    else isTagToAdd = false;
    // this.setScrollResetPos();
    this.orderService.modifyOrderTags({ Order_ID: order.Order_ID, Order_Tag: tag, isTagToAdd: isTagToAdd })
      .subscribe((orderTagdata) => {
        order.Tag_Names = orderTagdata.Tag_Names;
        order.Order_Tags = orderTagdata.Order_Tags;
        if (selectedTagsCopy.length > 0) {
          this.getOrders();
          this.matMenuTrigger.closeMenu();
        }
        else this.getTagCount();
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderTagging);
        this.matMenuTrigger.closeMenu();
      })
  }

  getOrderTags(order) {
    this.orderService.getOrderTags(order.Order_ID)
      .subscribe((ordTags) => {
        if (ordTags) {
          let getAllTagID = "";
          ordTags.forEach((tag) => {
            getAllTagID += "," + tag.Tag_ID;
          })
          order.Order_Tags = getAllTagID;
          order.Tag_Names = ordTags;
        }
        else order.Tag_Names = [];
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  menuOpened(order) {
    this.searchTag = '';
    this.associatedTags = [];
    this.orderTags = [];
    this.getAvailableTags(order).map((tag) => tag.on = false)
  }

  onSelectedOptionsChange(order) {
    this.timer.push(setTimeout(() => {
      this.orderTags = this.getAvailableTags(order).filter(tag => tag.on);
    }));
  }

  createExcel() {
    this.orderService.createOrdersDueExcelReport(this.orderBy, this.sortField, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags)
      .subscribe((blobData) => {
        if (blobData) this.composeAndDwnld(blobData);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  composeAndDwnld(blobData) {
    let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", "Report.xlsx");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  csvExport() {
    const fields = [
      {
        label: 'Sl No',
        value: 'serialNumber'
      },
      {
        label: 'Pippin Order Number',
        value: 'Titles_ID'
      },
      {
        label: 'Client Reference',
        value: 'File_ID'
      },
      {
        label: 'Client',
        value: (row, User_First_Name) => {
          if (row.Organization_Name) return row.User_First_Name + ' ' + row.User_Last_Name + ' - ' + row.Organization_Name;
          else return row.User_First_Name + ' ' + row.User_Last_Name;
        }
      },
      {
        label: 'Qualia Order',
        value: 'qualiaOrder'
      },
      {
        label: 'Owner/Seller',
        value: 'Property_First_Name'
      },
      {
        label: 'Property Address',
        value: (row, Property_Address_1) => {
          var completeAddress = '';
          if (row.Property_Address_1) completeAddress += row.Property_Address_1
          else return completeAddress;
          if (row.Property_Address_2) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_Address_2
          }
          if (row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_City + ', ' + row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          if (!row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          return completeAddress;
        }
      },
      {
        label: 'State',
        value: 'Property_State_Abbr'
      },
      {
        label: 'County',
        value: 'Property_County'
      },
      {
        label: 'Status',
        value: (row, Order_Status) => {
          if (row.Order_Status == CONSTANTS.orderStatus.received) {
            return 'Received';
          } else if (row.Order_Status == CONSTANTS.orderStatus.confirmed) {
            return 'Confirmed';
          } else if (row.Order_Status == CONSTANTS.orderStatus.assigned) {
            return 'Assigned';
          } else if (row.Order_Status == CONSTANTS.orderStatus.cancelled) {
            return 'Cancelled';
          } else if (row.Order_Status == CONSTANTS.orderStatus.processing) {
            return 'Processing';
          } else if (row.Order_Status == CONSTANTS.orderStatus.completed) {
            return 'Completed';
          } else if (row.Order_Status == CONSTANTS.orderStatus.quotecompleted) {
          return 'Completed';
        }
        }
      },
      {
        label: 'Product Type',
        value: 'Product_Description'
      },
      {
        label: 'Submission Date',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'MMM d, yyy');
        }
      },
      {
        label: 'Submission Time (ET)',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'h:mm a');
        }
      },
      {
        label: 'Completed Date',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Status == CONSTANTS.orderStatus.completed) return this._datePipe.transform(row.Order_Completion_Date_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Completed Time(ET)',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Status == CONSTANTS.orderStatus.completed) return this._datePipe.transform(row.Order_Completion_Date_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Due Date',
        value: (row, Order_ETA) => {
          if (row.Order_ETA) return this._datePipe.transform(row.Order_ETA_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Due Time (ET)',
        value: (row, Order_ETA) => {
          if (row.Order_ETA) return this._datePipe.transform(row.Order_ETA_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Handled By',
        value: (row, Admin_User_First_Name) => {
          if (row.Admin_User_First_Name) return `${row.Admin_User_First_Name} ${row.Admin_User_Last_Name}`;
          else return '';
        }
      },
      {
        label: 'Assigned Duration',
        value: (row, Order_Admin_ID) => {
            const duration = this.orderService.getAssignedDuration(row);
            return duration.replace(/[\(\)]/g, ''); // Remove parentheses
        }
      },
      {
        label: 'Searcher',
        value: (row, Order_Admin_ID) => {
          if (row.Order_Admin_ID && row.Abstrator_ID) return 'Abstractor';
          else if (row.Order_Admin_ID) return 'Pippin';
          else return '';
        }
      },
      {
        label: 'Search Master Type',
        value: (row, Order_Admin_ID) => {
          if(row.Order_Admin_ID) {
            if(row.Admin_User_Role == CONSTANTS.userRole.searchMaster){
              if(row.Admin_User_Type == CONSTANTS.userType.internalSM || row.Admin_User_Type == null) return "Internal";
              else if(row.Admin_User_Type == CONSTANTS.userType.externalSM) return "External"
            }
          } else return "";
        }
      },
      {
        label: 'Assigned Abstractor',
        value: (row, Abstrator_ID) => {
          if (row.Admin_User_First_Name && row.Abstrator_ID) return row.Abstractor_User_First_Name;
          else return '';
        }
      },
      {
        label: 'Priority Order',
        value: (row, Order_Creation_Date) => {
          if (row.Order_Priority == CONSTANTS.orderPriority.level2) return 'Yes';
          else return '';
        }
      },
      {
        label: 'Original ETA Date',
        value: (row, Order_Original_ETA) => {
          if (row.Order_Original_ETA) return this._datePipe.transform(row.Order_Original_ETA_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Original ETA Time (ET)',
        value: (row, Order_Original_ETA) => {
          if (row.Order_Original_ETA) return this._datePipe.transform(row.Order_Original_ETA_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Current Task Due Date',
        value: (row, Order_Internal_Due_Time) => {
          if (row.Order_Internal_Due_Time) return this._datePipe.transform(row.Order_Internal_Due_Time_EST, 'MMM d, yyyy');
          else return '';
        },
      },
      {
        label: 'Current Task Due Time (ET)',
        value: (row, Order_Internal_Due_Time) => {
          if (row.Order_Internal_Due_Time) return this._datePipe.transform(row.Order_Internal_Due_Time_EST, 'h:mm a');
          else return '';
        },
      },
      {
        label: 'Tags',
        value: 'Order_Tag_Name'
      },
      // {
      //   label: 'ETA Delay Reason',
      //   value: (row, Order_ETA_Change_Reason) => {
      //     if (row.Order_ETA_Change_Reason) return row.Order_ETA_Change_Reason;
      //     else return '';
      //   }
      // },
      {
        label : 'Latest Note',
        value: (row, Latest_Note) => {
          if (row.Latest_Note) return row.Latest_Note;
          else return '';
        },
      }
    ];
    this.orderService.downloadCSVfile(fields, this.orders, this.allActiveTags)
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  addOrderCountToTags(tagsOrderCount) {
    tagsOrderCount.forEach((chip) => {
      this.allActiveTags.forEach((activeChip) => {
        if (chip.Tag_ID == activeChip.Tag_ID) activeChip.Order_Count = chip.Order_Count;
      })
      this.allNegativeTags.forEach((negativeChip) => {
        if (chip.Tag_ID == negativeChip.Tag_ID) negativeChip.Order_Count = chip.Order_Count;
      })
    })
    this.allActiveTagsBackUp = this.allActiveTags.map((_arrayElement) => Object.assign({}, _arrayElement));
    this.allActiveTags.sort(this.orderService.sortTags);
  }

  trackOrders(order) {
    return order.Order_ID;
  }

  getSearchBarClass() {
    ;
    if (this.checkIfFilterPresent() && ((this.dueDate == 'Select all') || (this.dueDate == 'Due today'))) return 'col-sm-8';
    else if (this.checkIfFilterPresent() || ((this.dueDate == 'Select all') || (this.dueDate == 'Due today'))) return 'col-sm-10';
    else return 'col-sm-12';
  }
  orderAssignee(ordDetails) {
    var data = {};
    if (ordDetails.Organization_ID) data['Organization_ID'] = ordDetails.Organization_ID;
    // this.userService.getAllOrgAdminsToOrderAssign(data).subscribe((adminData) => {
    //   this.userService.getAllOrgSearchMasterToOrderAsign(data).subscribe((searchData) => {
      this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
        if (ordDetails.Order_Status == CONSTANTS.orderStatus.received || ordDetails.Order_Status == CONSTANTS.orderStatus.assigned) {

          let dataToScreener = {
            admins: adminData['admins'],
            Search_Masters: adminData['searchers'],
            Current_Admin: ordDetails.Order_Admin_ID
          }
          let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
            title: 'Screen order',
            message: dataToScreener
          }, ...this.config.getDialogOptions()}).afterClosed()
            .subscribe((res) => {
              if (res) {
                let msgType;
                if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
                else {
                  res.msgText = ""
                  msgType = CONSTANTS.messageType.internalNotes;
                }
                if(res.admin && res.getTimeToCompl){
                  if(ordDetails.Order_Admin_ID){
                    this.orderService.changeScreener(ordDetails.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
                    .subscribe((data) => {
                    this.getOrders();
                    }, (err) => {
                      console.log(err);
                      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                    })
                  }else{
                    this.orderService.screenOrder(ordDetails.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
                    .subscribe((data) => {
                      this.getOrders();
                    }, (err) => {
                      console.log(err);
                      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                    })
                  }
                }
                else{
                    this.orderService.unassignOrder(ordDetails.Order_ID,res.msgText,msgType,ordDetails.Order_Status).subscribe((data)=>{
                      this.getOrders()
                    }, (err) => {
                      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                  })
                }
              }
            });
        } else {
          var dataToDialog = {
            Order_ETA: ordDetails.Order_ETA,
            admins: adminData['admins'],
            Search_Masters: adminData['searchers'],
          };
          if (ordDetails.Order_Admin_ID) dataToDialog['Current_Admin'] = ordDetails.Order_Admin_ID;
          let disposable = this.matDialog.open(AssignComponent, {data:{
            title: 'Assign order',
            message: dataToDialog,
            listOfHolidays:this.holidayList
          }, ...this.config.getDialogOptions()}).afterClosed()
            .subscribe((res) => {
              if (res) {
                let msgType;
                if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
                else {
                  res.msgText = ""
                  msgType = CONSTANTS.messageType.internalNotes;
                }
                if(res.admin && res.getTimeToCompl){
                  if (ordDetails.Order_Admin_ID) {
                    if(this.isETAChanged(res.Order_ETA, ordDetails) && ordDetails.Order_Status >= CONSTANTS.orderStatus.confirmed) {
                      this.openETAChangeMsgPopup(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
                    } else {
                      this.changeOrderAssignee(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, '', '')
                    }
                  } else {
                    if(this.isETAChanged(res.Order_ETA, ordDetails) && ordDetails.Order_Status >= CONSTANTS.orderStatus.confirmed) {
                      this.openETAChangeMsgInterface(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
                    } else {
                      this.assignOrder(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, '', '')
                    }
                  }
                }
                else{
                    this.orderService.unassignOrder(ordDetails.Order_ID,res.msgText,msgType,ordDetails.Order_Status).subscribe((data)=>{
                      this.getOrders()
                    }, (err) => {
                      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                  })
                }
              }
            })
        };
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })
  }

  isETAChanged(eta, ordDetails) {   
    var oldETA;
    var newETA;
    // if(ordDetails.Order_ETA) oldETA = moment(ordDetails.Order_ETA).add(new Date().getTimezoneOffset()/60, 'hours').toISOString();
    // else oldETA = null;
    // if(eta) newETA = moment(eta).toISOString();
    // else newETA = null;
    if(ordDetails.Order_ETA) { 
      let estShift = momentTimeZone.tz(ordDetails.Order_ETA, 'America/New_York').isDST() ? 4 : 5;
      oldETA = momentTimeZone.tz(ordDetails.Order_ETA,'America/New_York').add(estShift, 'hours').format('MM-DD-YYYYTHH:mm');
    } else oldETA = null;
    if(eta) newETA = momentTimeZone.tz(eta, 'America/New_York').format('MM-DD-YYYYTHH:mm');
    else newETA = null;
    if(oldETA != newETA) return true;
    else return false;
  }

  openETAChangeMsgPopup(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange) {
    let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if(res.state) { 
          this.changeOrderAssignee(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
        }
      });
  }

  changeOrderAssignee(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID) {
    this.orderService.changeOrderAssign(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID)
    .subscribe((data) => {
      this.getOrders()
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
  }

  openETAChangeMsgInterface(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange) {
    let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if(res.state) { 
          this.assignOrder(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
        }
      });
  }

  assignOrder(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID) {
    this.orderService.initOrderProcess(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID)
      .subscribe((data) => {
        this.getOrders()
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })
  }

  getFontColor(hexColor) {
    return this.tagService.getFontColor(hexColor)
  }


  addCheckedProperty(orders) {
    orders.map((order) => {
      order.checked = false;
      return order;
    })
  }

  onOrdSelectEvt(data) {
    this.onOrdSelect(data.event, data.ordId, data.order, data.checked)
  }

  onOrdSelect(event, ordId, order, checked) {
    event.stopPropagation();
    if (this.ordsToassign.includes(ordId)) {
      var index = this.ordsToassign.indexOf(ordId);
      if (index > -1) {
        this.ordsToassign.splice(index, 1);
      }
    } else {
      this.ordsToassign.push(ordId);
    }
    if (checked) {
      var index = this.selectedOrgIds.findIndex((filterItem) => filterItem.Order_ID === order.Order_ID);
      if (index > -1) {
        this.selectedOrgIds.splice(index, 1);
      }
    } else {
      this.selectedOrgIds.push(order);
    }

  }

  showScreenerPopup() {
    var data = {};
    if(this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
    this.userService.getCommonAdminsNSearchers(data).subscribe((list) => {
        var dataToDialog = {
          Current_Admin:null,
          admins: list.adminList,
          Search_Masters: list.searcherList,
        };
        let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
          title: 'Screen order',
          message: dataToDialog
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }
              this.orderService.assignBulkOrds(this.ordsToassign, res.admin, null, res.msgText, msgType, res.getTimeToCompl, null, true)
                  .subscribe((data) => {
                    this.ordsToassign=[];
                    this.selectedOrgIds=[];
                    this.getOrders();
                  }, (err) => {
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                  })
            }
          });
    }, (err) =>{
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
  }  

  


  showOrderETAPopup() {
    var data = {};
    if (this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
    this.userService.getCommonAdminsNSearchers(data).subscribe((list) => {
      var dataToDialog = {
        Order_ETA: null,
        Current_Admin: null,
        admins: list.adminList,
        Search_Masters: list.searcherList,
        Multi_Orders: true,
      };
      let disposable = this.matDialog.open(AssignComponent, {data:{
        title: 'Assign order',
        message: dataToDialog,
        listOfHolidays:this.holidayList
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if (res) {
            let msgType;
            if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
            else {
              res.msgText = ""
              msgType = CONSTANTS.messageType.internalNotes;
            }
            this.orderService.assignBulkOrds(this.ordsToassign, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range,false,true)
              .subscribe((data) => {
                this.ordsToassign = [];
                this.selectedOrgIds = [];
                this.getOrders()
              }, (err) => {
                this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
              })
          }
        });
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
  }

  showDialogPopup() {
    let msg="Please select the order with similar status!"
    this.openErrorPopup(msg);   
  }

  multiselect() {
    var data = {};
    if (this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
    let multiSelectOrd = [];
    this.selectedOrgIds.filter((order) => multiSelectOrd.push(order.Order_Status));
  //   if (multiSelectOrd.every((orderStatus) => (orderStatus == CONSTANTS.orderStatus.assigned) || (orderStatus == CONSTANTS.orderStatus.received))) 
  //   this.showScreenerPopup();       
  //      else if (multiSelectOrd.every((orderStatus) => (orderStatus == CONSTANTS.orderStatus.confirmed) || (orderStatus == CONSTANTS.orderStatus.processing))) 
  //        this.showOrderETAPopup()     
  // else if(multiSelectOrd.every((orderStatus) => (orderStatus == CONSTANTS.orderStatus.received) || (orderStatus == CONSTANTS.orderStatus.assigned ||
  //   (orderStatus == CONSTANTS.orderStatus.confirmed)||(orderStatus == CONSTANTS.orderStatus.processing) )))this.showDialogPopup()
  if(multiSelectOrd.every((orderStatus) => (orderStatus == CONSTANTS.orderStatus.received) || (orderStatus == CONSTANTS.orderStatus.assigned ||
    (orderStatus == CONSTANTS.orderStatus.confirmed)||(orderStatus == CONSTANTS.orderStatus.processing) ))) this.showOrderETAPopup()     
    }
  assignSelectedOrds() {
    var data = {};
    if (this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
     this.userService.getCommonAdminsNSearchers(data).subscribe((list) => {
      var dataToDialog = {
        Order_ETA: null,
        Current_Admin: null,
        admins: list.adminList,
        Search_Masters: list.searcherList,
        Multi_Orders: true,
      };
      // dataToDialog['Current_Admin'] = null;
      let disposable = this.matDialog.open(AssignComponent, {data:{
        title: 'Assign order',
        message: dataToDialog,
        listOfHolidays:this.holidayList
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if (res) {
            let msgType;
            if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
            else {
              res.msgText = ""
              msgType = CONSTANTS.messageType.internalNotes;
            }
            this.orderService.assignBulkOrds(this.ordsToassign, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
              .subscribe((data) => {
                this.ordsToassign = [];
                this.selectedOrgIds = [];
                this.getOrders()
              }, (err) => {
                this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
              })
          }
        });
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
  }

  removeDuplicateOrg(orgIDs) {
    return orgIDs.filter((orgId, index) => orgIDs.indexOf(orgId) === index);
  }

  getTagCount() {
    this.orderService.getDueOrdersCount(this.getSearchString(), this.filterBy, this.selectedTags)
      .subscribe((data) => {
        this.tagsOrderCount = data;
        this.addOrderCountToTags(data);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })
  }

  getRadioValue(event) {    
    if(event){
      let handleByObj = {
        value : this.handleBy
      }      
      this.detectTypeChange(handleByObj);
    }
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

  getFullAddress(order){
    let completeAddress = '';
    if (order.Property_Address_1) completeAddress += order.Property_Address_1
    if (order.Property_Address_2) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_Address_2
    }
    if (order.Property_City) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_City
    }
    if (order.Property_State_Abbr) {
      if (completeAddress) completeAddress += ', '
      completeAddress += order.Property_State_Abbr
    }
    if (order.Property_ZipCode) {
      if (completeAddress) completeAddress += ' '
      return completeAddress += order.Property_ZipCode
    }
  }
  
  getClientFullName(order) {
    if(order.Organization_Name) return order.User_First_Name +" "+ order.User_Last_Name + " - "  +order.Organization_Name;
    else return order.User_First_Name +" "+ order.User_Last_Name 
  }
  
  getAbstractorFullName(order) {
    return order.Abstractor_User_First_Name;
  }
  
  getAdminFullName(order) {
    return this.firstLetterCaps(order.Admin_User_First_Name) + " "+ this.firstLetterCaps(order.Admin_User_Last_Name)
  }

  firstLetterCaps(data){
    if (typeof data !== 'string') return ''
    return data.charAt(0).toUpperCase() + data.slice(1)
  }

  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
     setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
      
      }
      // this.holidayList = holidays;
    }, (err) =>{
      console.log("Error",err)
    })
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  isSuperAdmin(){
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }
}
