// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead. 
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    basePath: 'https://serverhf.pippintitle.com',
    basePathSitusAMCService: 'https://situsamc-qa.pippintitle.com',
    access_token: 'xWeh4H0QgqIfM4k5cGW7XQQdIxLXxf9k',
    clientHome:'https://apphf.pippintitle.com',
    adminHome:  'https://appadminhf.pippintitle.com',
    clientBasePath: 'https://serverhf.pippintitle.com',
    qualiaOrgId: '6c5e6488-7187-40b7-9f66-dc374c99ecdb',
    superAdminId: '4c96fd2b-ba23-4451-b1be-f55fac496e87',
    salesMarketingGrpId : '6',
    newClientTagId:'156',
    priorityClientTagId: '104',
    firstOrderTagID: "144",
    prePaidTagId:'134',
    partiallyDelivered: '113',
    absDoubleAssign: '152',
    commitmentTypingTagID: '184',
    QCTagId: 41,
    SearchingTagId: 40,
    SoftProTagId: 191,
    AwaitDlvryTagId: 26,
    AwaitPartDlvryTagId: 187,
    vapidPublicKey :"BG72HPTS_Ji63weU-IoYHz62b6A_fpuuQ124rESQqMBl4kX-pupOoROKxJDznNLfOHqKaOauZJZhhaoHzuTwgfI",
    aws:{
      apiVersion:'2006-03-01',
      region:'us-west-2',
      bucket:'dev.fs.pippintitle',
      credentials:'us-west-2:1a57f7a1-ea10-4f25-86a8-295c8464d9fd'
    },
    nameSearchProductId:1019,
    updateSearchProductId: 5,
    copyChargeProductId:7,
    westFieldBank: "bbf6959f-3c98-4c12-8c0d-70af3a09900f",
    baseProductExpenseId: 97,
    googleFormLink:'https://docs.google.com/forms/d/e/1FAIpQLScjpTfV18YvOmgZQvy8Vjkdt6nNu-RH82qN2djuGtD18agXAg/',
    texasT00Link:'https://drive.google.com/uc?id=18a4h2c8Oemi8u7Chw6rjE7ARVoL85zAp&export=download',
    runsheetLink:'https://drive.google.com/uc?id=11j0DdlxEuYijnLkMoghz70woneCU8Xyl&export=download',
    qualiaExpenseId: 26,
    qualia_ms_url: 'https://qualiaqa.pippintitle.com',
    wimbaCpnyId: "bcbccf47-f7b7-4752-a9a4-3c7c81f5dfdb",
    wimbaTemplateLink: "https://drive.google.com/uc?id=1fVCCRjrwSwo4B2HCkkUaN6duD_YPcY3s&export=download",
    wimbaproductId: 1053,
    emailIdOptions: ['emailqa@pippintitle.com', 'emailqa2@pippintitle.com'],
    noCommitId:31,
    westcorOrgId : '70b68b98-40be-49b7-aeca-ddb6858d1250',
    currentOwnerSearch_Product_ID : 1
  };