
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { CONSTANTS } from '../../app.constants';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http'
@Injectable()
export class ClientsService {

  newClientCount = 0;
  direct: HttpClient;
  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private handler: HttpBackend
  ) { 
    this.direct = new HttpClient(this.handler);
  }

  getClients(type, pageNum, sortBy, orderBy, searchStr, filterBy) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/admin/clients/' + type + '/admin/' + this.auth.getUserId();
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, filterBy, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllClientsToAddOrgs(orgId) {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/allclients/' + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getClientsOnBg(type, pageNum, sortBy, orderBy, searchStr, filterBy) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/admin/clients/' + type + '/admin/' + this.auth.getUserId();
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.direct.post(url, filterBy, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  userAction(action, userId) {
    let data = {
      User_ID: userId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/' + action;
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateOrgIdOfUser(orgId, userId, emailID) {
    let data = {
      User_ID: userId,
      Organization_ID: orgId,
      User_Login_Name: emailID,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/org/update';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deleteClient(clientId){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + `/v2/user/deleteUser/${clientId}`;
    return this.http.post(url, {clientId}, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  sendMessage(model, clientId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/messages/user/' + clientId + '/admin/' + this.auth.getUserId();
    return this.http.post(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createClient(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/client/new';
    return this.http.post(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  
  getClientDetails(clientId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/client/profile/' + clientId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  resetClienPassword(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/client/reset/password';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editClientDetails(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/client/update';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deactivateUser(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/client/disable';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }
  activateUser(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/client/enable';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getNewClientCount() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/newclientcount';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        this.newClientCount = response;
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getNewClientCountOnBg() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/newclientcount';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        this.newClientCount = response;
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getIndividualClients() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/all/clients/individual';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getActiveIndividualClients() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/all/clients/individual/active';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  resetClientPasswordOnFly(model) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/client/password/reset';
    return this.http.put(url, { User_Password: model }, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getLoginInfo(id){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/loginHistory/' + id;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }


  activateClientUser(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/account/activate';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  createOrgGroup(data) {
    data.Admin_ID = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/groups/create';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrgDeliveryGroups(orgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/groups/all/'+orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getGroupMembers(groupId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/groups/'+groupId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  deleteDeliveryGroup(groupId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/groups/delete/'+groupId+'/admin/'+this.auth.getUserId();
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  editDeleveryGroup(data) {
    data.Admin_ID = this.auth.getUserId();
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/groups/edit';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  setEmailStatusForCompCli(EmailStatus, orgId) {
    let data = {
      Org_Email_Client_Status: EmailStatus,
      Organization_ID: orgId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/groups/emailclient/status';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getUserInfo(id){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/users/userInfo/' + id;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }


  updateDemoStatusForIndCli(toggleStatus, userId) {
    let data = {
      User_Demo_Flag: toggleStatus,
      User_ID: userId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/demo/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateNewClientStatusForInd(toggleStatus, userId) {
    let data = {
      User_New_Client_Flag: toggleStatus,
      User_ID: userId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/new/client/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updInvDelStatus(toggleStatus, userId) {
    let data = {
      User_AddInvoice_Deliverables: toggleStatus,
      User_ID: userId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/add/invoiceTo/deliverables/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateNewClientForInd(toggleStatus, userId) {
    let data = {
      New_Client: toggleStatus,
      User_ID: userId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/new/client';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateAutoScreenStatusForInd(toggleStatus, userId) {
    let data = {
      User_Auto_Screen_Flag: toggleStatus,
      User_ID: userId,
      Admin_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/users/auto/screen/status';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

}